import { makeAutoObservable } from "mobx";
import { makePersistable } from "mobx-persist-store";
class Store {
  channel = "";

  loginStatus = false;

  blockHeight = "";

  blockId = "";

  constructor() {
    makeAutoObservable(this);
    makePersistable(this, {
      name: "AdminStore",
      properties: ["channel", "loginStatus", "blockHeight", "blockId"],
      storage: window.localStorage,
    });
  }

  actionChannel(e) {
    this.channel = e;
  }

  actionLoginstatus(status) {
    this.loginStatus = status;
  }

  actionBlockHeight(e) {
    this.blockHeight = e;
  }

  actionBlockId(e) {
    this.blockId = e;
  }
}

export default new Store();
