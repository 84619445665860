/* eslint-disable react/react-in-jsx-scope */
import LoginView from "@/layouts/login.jsx";
import AdminView from "@/layouts/admin.jsx";
import BlockView from "@/pages/block/index.jsx";
import BlockInfoView from "@/pages/blockInfo/index.jsx";
import BlockDealInfoView from "@/pages/blockDealInfo/index.jsx";
import HomeView from "@/pages/home/index.jsx";
import MonitorView from "@/pages/monitor/index.jsx";
import NetWorkView from "@/pages/netWork/index.jsx";
import TransactionView from "@/pages/transaction/index.jsx";
const routes = [
  {
    path: "/login",
    component: LoginView,
  },
  {
    path: "/",
    component: AdminView,
    children: [
      {
        path: "/block",
        component: BlockView,
      },
      {
        path: "/blockInfo",
        component: BlockInfoView,
      },
      {
        path: "/blockDealInfo",
        component: BlockDealInfoView,
      },
      {
        path: "/home",
        component: HomeView,
      },
      {
        path: "/monitor",
        component: MonitorView,
      },
      {
        path: "/netWork",
        component: NetWorkView,
      },
      {
        path: "/transaction",
        component: TransactionView,
      },
    ],
  },
];

export default routes;
