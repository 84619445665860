/* eslint-disable react/prop-types */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Link } from "react-router-dom";
import { request } from "@/utils/request";
import { getTimeFormat } from "@/utils/function";

import { Table, Tag, Button, Space, ConfigProvider, Tooltip } from "antd";
import ConfigDesc from "@/components/desc/configDesc.jsx";

import decorateIcon from "@/assets/static/decorate_icon.png";
import decorateImg from "@/assets/static/decorate_img.png";

import styles from "./index.module.less";

const columns = [
  {
    title: "序号",
    dataIndex: "xh",
    key: "xh",
    width: 50
  },
  {
    title: "存证hash",
    dataIndex: "hash",
    key: "hash",
    ellipsis: true,
    render: (text, record) => (
      <div className={styles["block-text_color"]}>
        <Link to={{ pathname: "/blockDealInfo" }} onClick={() => store.actionBlockId(record.id)}>
         <Tooltip title={text}>{text}</Tooltip>
        </Link>
      </div>
    ),
  },
  {
    title: "存证时间",
    dataIndex: "tx_time",
    key: "tx_time",
    ellipsis: true,
    width: 200,
    render: (text) => {
      return <Tooltip title={getTimeFormat("y-m-d h:i:s", text)}><span>{getTimeFormat("y-m-d h:i:s", text)}</span></Tooltip>;
    },
  },
  {
    title: "智能合约",
    dataIndex: "chaincode_id",
    key: "chaincode_id",
    width: 100
  },
  {
    title: "存证状态",
    dataIndex: "valid_status",
    key: "valid_status",
    render: (text) => {
      let componet = null;
      switch (text) {
        case true:
          componet = <Tag color="green">成功</Tag>;
          break;
        case false:
          componet = <Tag color="red">失败</Tag>;
          break;
      }
      return componet;
    },
    width: 100
  },
];

const BlockInfo = (props) => {
  let [dataSource, setDataSource] = useState([]);
  let [blockDetailList, setBlockDetailList] = useState([
    // { label: "通道", value: null, type: "channel" },
    { label: "区块高度", value: null, type: "height" },
    { label: "区块哈希", value: null, type: "block_hash" },
    { label: "数据哈希", value: null, type: "data_hash" },
    { label: "前序哈希", value: null, type: "block_pre_hash" },
  ]);
  let [abstractList, setAbstractList] = useState([
    {
      gutter: 4,
      value: null,
      label: "块高度",
      type: "text",
      dataIndex: "height",
      width: "50%",
    },
    {
      gutter: 4,
      value: null,
      label: "区块时间",
      type: "text",
      dataIndex: "time",
      width: "50%",
    },
    {
      gutter: 4,
      value: null,
      label: "存证量",
      type: "text",
      dataIndex: "count",
      width: "50%",
    },
    {
      gutter: 4,
      value: null,
      label: "区块大小(Bytes)",
      type: "text",
      dataIndex: "size",
      width: "50%",
    },
  ]);

  let [heightInterval, setHeightInterval] = useState({ min: 0, max: 0 });
  let [current, setCurrent] = useState(store.blockHeight); // 当前区块

  // 查询最新的区块高度
  const getqueryHeight = async (channel) => {
    let res = await request("get", "/v3/fabric/monitoring/queryHeight", {
      channel
    });
    if(res.code == 0) {
       setHeightInterval({ min: 0, max: res.data })
    }
  }

  // 查询区块详情
  const getBlockDetail = async (channel, height) => {
    let res = await request("get", "/v3/fabric/monitoring/blockDetail", {
      channel,
      height,
    });
    if (res.code == 0) {
      let arr = blockDetailList.map((item) => {
        item.value = res.data[item.type];
        return item;
      });
      setBlockDetailList(arr);
      let arr1 = abstractList.map((item) => {
        if (item.dataIndex == "time") {
          item.value = getTimeFormat("y-m-d h:i:s", res.data[item.dataIndex]);
        } else {
          item.value = res.data[item.dataIndex];
        }
        return item;
      });
      setAbstractList(arr1);
    }
  };

  // 存证信息查询
  const txsSummaryByPage = async (data) => {
    let res = await request(
      "get",
      "/v3/fabric/monitoring/txsSummaryByPage",
      data
    );
    if (res.code == 0) {
      let arr = res.data.data.map((item, index) =>
        Object.assign({}, item, { key: index, xh: index + 1 })
      );
      setDataSource(arr);
    }
  };

  const prevOnextHeight = (type) => {
    try {
      let count = current;
      switch (type) {
        case 0:
          count -= 1;
          break;
        case 1:
          count += 1;
          break;
      }
      setCurrent(count);
    } catch (error) {
      console.log("error", error);
    }
  };

  useEffect(() => {
    getqueryHeight(store.channel);
    getBlockDetail(store.channel, current);
    txsSummaryByPage({
      channel: store.channel,
      count: 10,
      page: 1,
      block_height: current,
    });
  }, [current, store.channel]);

  return (
    <div className={styles["blockInfo"]}>
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#3D71F6",
              algorithm: true,
            },
          },
        }}
      >
        <div className={styles["blockInfo-detail"]}>
          <div className={styles["blockInfo-detail_title"]}>
            区块详情
            <img style={{ display: "none" }} src={decorateIcon} />
            <div className={styles["title-query"]}>
              <Space size={30}>
                <Button
                  type="primary"
                  disabled={
                    Object.is(current, heightInterval["min"]) ? true : false
                  }
                  onClick={() => prevOnextHeight(0)}
                >
                  上一区块
                </Button>
                <Button
                  type="default"
                  disabled={
                    Object.is(current, heightInterval["max"]) ? true : false
                  }
                  onClick={() => prevOnextHeight(1)}
                >
                  下一区块
                </Button>
              </Space>
            </div>
          </div>
          <div className={styles["blockInfo-detail_info"]}>
            <img src={decorateImg} />
            <div className={styles["info-desc"]}>
              <div className={styles["info-title"]}>基本信息</div>
              {blockDetailList.map((item, index) => {
                return (
                  <div key={index} className={styles["info-desc_item"]}>
                    <label>{item.label}&nbsp;:</label>
                    <span>&nbsp;&nbsp;{item.value}</span>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles["blockInfo-detail"]}>
          <div className={styles["blockInfo-detail_info"]}>
            <div className={styles["info-desc"]}>
              <div className={styles["info-title"]}>摘要信息</div>
              <div
                className={`${styles["info-desc_value"]} ${styles["flex-wrap"]}`}
              >
                {abstractList.map((item, index) => {
                  const { dataIndex, type, label, value, gutter, width } = item;
                  return (
                    <ConfigDesc
                      {...{ dataIndex, type, label, value, gutter, width }}
                      key={index}
                    ></ConfigDesc>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        <div className={styles["blockInfo-exchange"]}>
          <div className={styles["blockInfo-exchange_title"]}>存证信息</div>
          <div className={styles["blockInfo-exchage_info"]}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size={"middle"}
            />
          </div>
        </div>
      </ConfigProvider>
    </div>
  );
};

export default observer(BlockInfo);
