/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Pagination } from "antd";

import nextIcon from "@/assets/static/next_icon.png";
import previousIcon from "@/assets/static/previous_icon.png";

export default class ConfigPagination extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const { total, paginationFunc } = this.props;
    return (
      <Pagination
        total={total}
        onChange={(page, pageSize) => paginationFunc(page, pageSize)}
        showTotal={() => `共 ${total} 项数据`}
        showSizeChanger
        showQuickJumper
        // hideOnSinglePage
        itemRender={(_, type, originalElement) => {
          if (type === "prev") {
            return (
              <img
                src={previousIcon}
                style={{ width: 20, verticalAlign: "-6px" }}
              />
            );
          }
          if (type === "next") {
            return (
              <img
                src={nextIcon}
                style={{ width: 20, verticalAlign: "-6px" }}
              />
            );
          }
          return originalElement;
        }}
      />
    );
  }
}
