// 时间换算
export const getTimeFormat = (formatStr, fdate) => {
  const addZero = (s) => {
    return s < 10 ? "0" + s : s;
  };
  let fTime,
    fStr = "ymdhis";
  if (!formatStr) formatStr = "y-m-d h:i:s";
  if (fdate) fTime = new Date(fdate);
  else fTime = new Date();
  let formatArr = [
    fTime.getFullYear().toString(),
    addZero(fTime.getMonth() + 1).toString(),
    addZero(fTime.getDate()).toString(),
    addZero(fTime.getHours()).toString(),
    addZero(fTime.getMinutes()).toString(),
    addZero(fTime.getSeconds()).toString(),
  ];
  for (let i = 0; i < formatArr.length; i++) {
    formatStr = formatStr.replace(fStr.charAt(i), formatArr[i]);
  }
  return formatStr;
};

// 计算月份差
export const datemonth = (date1, date2) => {
  // 拆分年月日
  date1 = date1.split("-");
  // 得到月数
  date1 = parseInt(date1[0]) * 12 + parseInt(date1[1]);
  // 拆分年月日
  date2 = date2.split("-");
  // 得到月数
  date2 = parseInt(date2[0]) * 12 + parseInt(date2[1]);
  var m = Math.abs(date1 - date2); //Math.abs()取绝对值
  return m;
};
// 根据不同屏幕宽度换算字体大小
export const transformFontSize = (fontSize, defineWidth) => {
  const width = document.documentElement.clientWidth; // 获取屏幕宽度
  const ratio = width / defineWidth; // 取下整
  return parseInt(fontSize * ratio);
};

// 判断是否是json
export const jsJson = (str) => {
  if(typeof str == 'string') {
    try {
       let obj = JSON.parse(str);
       if(typeof obj == 'object') {
         return true
       } else {
         return false
       }
    } catch(e) {
       return false
    }

  }
}

// json深层次遍历解析
export const analysisJson = (obj) => {
    for(let key in obj) {
       if(jsJson(obj[key])) {
          obj[key] = JSON.parse(obj[key]);
          analysisJson(obj[key])
       }
    }
    return obj;
}