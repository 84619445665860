import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import {
  Select,
  Table,
  Space,
  DatePicker,
  Input,
  Button,
  Tag,
  ConfigProvider,
  Tooltip
} from "antd";

import ConfigPagination from "@/components/pagination/configPagination.jsx";

import { Link } from "react-router-dom";
import { request } from "@/utils/request";
import { getTimeFormat } from "@/utils/function";
import styles from "./index.module.less";

import ripple1Icon from "@/assets/static/ripple1_icon.png";

import previousIcon from "@/assets/static/previous_icon.png";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "序号",
    dataIndex: "xh",
    key: "xh",
    width: 50
  },
  {
    title: "存证hash",
    dataIndex: "hash",
    key: "hash",
    ellipsis: true,
    render: (text, record) => (
      <div className={styles["transaction-text_color"]}>
        <Link to={{ pathname: "/blockDealInfo" }} onClick={() => store.actionBlockId(record.id)}>
          <Tooltip title={text}>{text}</Tooltip>
        </Link>
      </div>
    ),
  },
  {
    title: "存证时间",
    dataIndex: "tx_time",
    key: "tx_time",
    ellipsis: true,
    width: 200,
    render: (text) => {
      return <Tooltip title={getTimeFormat("y-m-d h:i:s", text)}><span>{getTimeFormat("y-m-d h:i:s", text)}</span></Tooltip>;
    },
  },
  {
    title: "智能合约",
    dataIndex: "chaincode_id",
    key: "chaincode_id",
    ellipsis: true,
    width: 100,
    render: (text) => {
      return <Tooltip title={text}><span>{text}</span></Tooltip>;
    },
  },
  {
    title: "版本",
    dataIndex: "chaincode_version",
    key: "chaincode_version",
    width: 50
  },
  {
    title: "类型",
    dataIndex: "tx_type",
    key: "tx_type",
    render: (text) => {
      let componet = null;
      switch (text) {
        case 3:
          componet = <span>普通存证</span>;
          break;
        case 1:
          componet = <span>配置存证</span>;
          break;
      }
      return componet;
    },
    width: 100
  },
  {
    title: "存证状态",
    dataIndex: "valid_status",
    key: "valid_status",
    render: (text) => {
      let componet = null;
      switch (text) {
        case true:
          componet = <Tag color="green" bordered>成功</Tag>;
          break;
        case false:
          componet = <Tag color="red" bordered>失败</Tag>;
          break;
      }
      return componet;
    },
    width: 100
  },
];

const Transaction = () => {
  const [query, setQuery] = useState({
    start_time: "",
    stop_time: "",
    block_height: "",
    hash: "",
    chaincode_id: "",
    valid_status: "",
    tx_type: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    count: 10,
  });

  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  // 查询时间戳
  const timestampQuery = (e) => {
    let timestamp1 = "",
      timestamp2 = "";
    if (e) {
      timestamp1 = new Date(e[0]).getTime();
      timestamp2 = new Date(e[1]).getTime();
    }
    setQuery({
      start_time: `${timestamp1}`,
      stop_time: `${timestamp2}`,
    });
  };

  // 查询值
  const paramsQuery = (params, e) => {
    setQuery({
      [params]: e,
    });
  };

  // 查询列表数据
  const listQuery = () => {
    txsSummaryByPage(
      Object.assign({}, query, { channel: store.channel, ...pagination })
    );
  };

  // 查询存证概要信息
  const txsSummaryByPage = async (data) => {
    let res = await request(
      "get",
      "/v3/fabric/monitoring/txsSummaryByPage",
      data
    );
    if (res.code == 0) {
      console.log("获取存证概要信息列表", res);
      if (res.data.data) {
        let arr = res.data.data.map((item, index) =>
          Object.assign({}, item, { key: index, xh: index + 1 })
        );
        setTotal(res.data.counts);
        setDataSource(arr);
      } else {
        setTotal(0);
        setDataSource([]);
      }
    }
  };

  useEffect(() => {
    listQuery();
  }, [pagination]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#3D71F6",
            algorithm: true,
          },
        },
      }}
    >
      <div className={styles["transaction"]}>
        <img src={ripple1Icon} />
        <div className={styles["transaction-header"]}>
          <div className={styles["transaction-header_query"]}>
            <Space size={12}>
              <RangePicker
                showTime
                onChange={(e) => {
                  timestampQuery(e);
                }}
              />
              <Input
                placeholder="存证hash"
                prefix={<img src={previousIcon} style={{ width: 20 }} />}
                onChange={(e) => {
                  paramsQuery("hash", e.target.value);
                }}
              />
              <Input
                placeholder="智能合约"
                prefix={<img src={previousIcon} style={{ width: 20 }} />}
                onChange={(e) => {
                  paramsQuery("chaincode_id", e.target.value);
                }}
              />
              <Select
                placeholder="存证状态"
                style={{ width: 150 }}
                allowClear
                options={[
                  { label: "成功", value: 1 },
                  { label: "失败", value: 0 },
                ]}
                onChange={(e) => paramsQuery("valid_status", e)}
              />
              <Select
                placeholder="存证类型"
                style={{ width: 150 }}
                allowClear
                options={[
                  { label: "普通存证", value: 3 },
                  { label: "配置存证", value: 1 },
                ]}
                onChange={(e) => paramsQuery("tx_type", e)}
              />
              <Button type="primary" onClick={() => listQuery()}>
                刷新
              </Button>
            </Space>
          </div>
        </div>
        <div className={styles["transaction-table"]}>
          <div className={styles["transaction-table_info"]}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size={"middle"}
            />
            <div className={styles["info-pagination"]}>
              <ConfigPagination total={total} paginationFunc={(page, pageSize) => {setPagination({ page, count: pageSize })}} />
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default observer(Transaction);
