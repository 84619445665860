import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Table, Select, Tooltip } from "antd";
import { Link } from "react-router-dom";
import { request } from "@/utils/request";
import { getTimeFormat } from "@/utils/function";
import ConfigEcharts from "@/components/echarts/configEcharts";
import { transformFontSize } from "@/utils/function";

import styles from "./index.module.less";
import * as echarts from "echarts";
import ellipse998Icon from "@/assets/static/ellipse998_icon.png";
import imageIcon from "@/assets/static/image_icon.png";
import detailIcon from "@/assets/static/detail_icon.png";

import fabricIcon from "@/assets/static/fabric_icon.png";
import raftIcon from "@/assets/static/raft_icon.png";
import zzzsIcon from "@/assets/static/zzzs_icon.png";
import jdzsIcon from "@/assets/static/jdzs_icon.png";
import zlhyIcon from "@/assets/static/zlhy_icon.png";
import tdslIcon from "@/assets/static/tdsl_icon.png";
import ellipse999Icon from "@/assets/static/ellipse999_icon.png";

import decorateIcon from "@/assets/static/decorate_icon.png";
import arrowleftIcon from "@/assets/static/arrowleft_icon.png";

const columns = [
  {
    title: "序号",
    dataIndex: "xh",
    key: "xh",
    render: (text) => (
      <div
        className={`${styles[`table-columns_xh`]} ${styles[`xh-color${text}`]}`}
      >
        {text}
      </div>
    ),
    width: 50,
  },
  {
    title: "Hash值",
    dataIndex: "hash",
    key: "hash",
    ellipsis: true,
    render: (text) => (
      <div className={`${styles[`table-columns_hash`]}`}><Tooltip title={text}>{text}</Tooltip></div>
    ),
  },
  {
    title: "存证时间",
    dataIndex: "tx_time",
    key: "tx_time",
    ellipsis: true,
    width: 200,
    render: (text) => <Tooltip title={getTimeFormat("y-m-d h:i:s", text)}><span>{getTimeFormat("y-m-d h:i:s", text)}</span></Tooltip>
  },
  {
    title: "状态",
    dataIndex: "valid_status",
    key: "valid_status",
    render: (text) => (
      <div
        className={
          text == true
            ? styles["status-color_success"]
            : styles["status-color_fail"]
        }
      >
        {text == true ? "成功" : "失败"}
      </div>
    ),
    width: 100
  },
  {
    title: "所属块",
    dataIndex: "block_height",
    key: "block_height",
    render: (text) => <div className={styles["block-color"]}>{text}</div>,
    width: 100
  },
];

const Home = () => {
  const [blocksBrief, setBlocksBrief] = useState([]);
  const [txsBrief, setTxsBrief] = useState([]);
  const [baseInfoList, setBaseInfoList] = useState([
    {
      icon: fabricIcon,
      name: null,
      title: "区块高度",
      type: "totalBlock",
      decoration: "01",
    },
    {
      icon: raftIcon,
      name: null,
      title: "共识类型",
      type: "consensus",
      decoration: "02",
    },
    {
      icon: zzzsIcon,
      name: null,
      title: "用户数",
      type: "totalOrgs",
      decoration: "03",
    },
    {
      icon: jdzsIcon,
      name: null,
      title: "节点总数",
      type: "totalPeers",
      decoration: "04",
    },
    {
      icon: zlhyIcon,
      name: null,
      title: "智能合约数",
      type: "totalChaincodes",
      decoration: "05",
    },
    {
      icon: tdslIcon,
      name: null,
      title: "存证数量",
      type: "totalTx",
      decoration: "06",
    },
  ]);

  // 柱状图
  const [barInfo, setBarInfo] = useState(null);
  // 饼图
  const [pieInfo, setPieInfo] = useState(null);
  // 比例图
  const [gaugeInfo, setGaugeInfo] = useState(null);

  // 通道
  const [channels, setChannels] = useState([]);

  // 查询所有通道
  const getAllBrief = async () => {
    let res = await request("get", "/v3/fabric/channel/allBrief");
    if (res.code == 0) {
      store.actionChannel(res.data[0]);
      setChannels(res.data.map((v) => ({ value: v, label: v })));
    }
  };

  // 统计基本元素数量
  const getBaseInfoQuery = async () => {
    let res = await request("get", "/v3/config/baseInfo/query");
    if (res.code == 0) {
      let arr = baseInfoList.map((item) => {
        item.name = res.data[item.type];
        return item;
      });
      setBaseInfoList(arr);
    }
  };

  // 实时区块信息
  const getBlocksBrief = async (num, channel) => {
    let res = await request("get", "/v3/fabric/monitoring/blocksBrief", {
      channel,
      num,
    });
    if (res.code == 0) {
      setBlocksBrief(res.data);
    }
  };

  // 实时存证信息
  const getTxsBrief = async (num, channel) => {
    let res = await request("get", "/v3/fabric/monitoring/txsBrief", {
      channel,
      num,
    });
    if (res.code == 0) {
      let result = res.data.map((item, index) =>
        Object.assign({}, item, { key: index, xh: index + 1 })
      );
      setTxsBrief(result);
    }
  };

  // 获取通道中的区块数
  const getStaticBlock = async () => {
    let res = await request("get", "/v3/fabric/static/users");
    if (res.code == 0) {
      const userNumCharts = echarts.init(
        document.getElementById("echart1Id")
      );

      const xAxisData = [], seriesData = []
      res.data.reverse().map((item) => {
        xAxisData.push(item.create_time.substring(11, 16))
        seriesData.push(item.user_count)
      })
      userNumCharts.setOption({
        color: ["rgba(3, 100, 255, .8)"],
        grid: {
          top: 40,
          right: 25,
          left: 10,
          bottom: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#fff",
          borderColor: "#DCDCDC",
          borderWidth: 1,
          textStyle: {
            color: "rgba(0, 0, 0, .9)",
          },
          formatter: function (params) {
            let str = ``;
            for (let i = 0; i < params.length; i++) {
              const paramsItem = params[i], dataItem = res.data[paramsItem.dataIndex]
              console.log('params', params)

              str += `<div style="width: calc(141vw / 19.2); padding: calc(16vw / 19.2) 0 calc(20vw / 19.2) calc(16vw / 19.2);"><div style="color: rgba(0, 0, 0, .4); font-size: calc(12vw / 19.2); line-height: calc(20vw / 19.2);">${dataItem.create_time.substring(0, 16)}</div> 
              <div style="margin-top: calc(16vw / 19.2);display: flex; flex-direction: row; align-items: center;"> <div style="width: calc(8vw / 19.2); height: calc(8vw / 19.2); background-color: #3D71F6; border-radius: 50%; margin-right: calc(12vw / 19.2)"></div>  ${paramsItem.seriesName} ${paramsItem.value}</div></div>`;
            }
            return str;
          },
        },
        xAxis: {
          type: "category",
          offset: 0,
          boundaryGap: false,
          data: xAxisData,
          name: "",
          axisTick: {
            show: true,
            interval: 2,
            lineStyle: {
              color: '#D7DAE1'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#696969",
            interval: 2,
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(57, 70, 106, .2)'
            },
          },
        },
        yAxis: {
          //name: "万元",
          type: "value",
          nameTextStyle: {
            color: "#696969",
            align: "center",
            fontWeight: "400",
          },
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#696969",
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(57, 70, 106, .1)',
            },
          },
        },
        series: [
          {
            name: "用户数",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: seriesData,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  { offset: 0, color: "rgba(3, 100, 255, .8)" },
                  // { offset: 0.5, color: "rgba(221, 190, 76, .2)" },
                  { offset: 1, color: "rgba(3, 100, 255, 0)" },
                ],
                global: false,
              },
            },
            lineStyle: {
              // 阴影部分
              shadowOffsetX: 0, // 折线的X偏移
              shadowOffsetY: 4, // 折线的Y偏移
              shadowBlur: 4, // 折线模糊
              shadowColor: "rgba(3, 100, 255, .1)", //折线颜色
            },
          },
        ],
      });
    }
  };

  // 区块与存证数统计
  const getTxBlock = async (channel) => {
    let res = await request("get", "/v3/fabric/static/txs");
    if (res.code == 0) {
      // setPieInfo({
      //   data: res.data.map((item) => ({ name: item.type, value: item.count })),
      //   name: "存证数",
      // });
      const dealNumCharts = echarts.init(
        document.getElementById("echart2Id")
      );

      const xAxisData = [], seriesData = []
      res.data.reverse().map((item) => {
        xAxisData.push(item.create_time.substring(11, 16))
        seriesData.push(item.tx_count)
      })
      dealNumCharts.setOption({
        color: ["rgba(246, 173, 58)"],
        grid: {
          top: 40,
          right: 25,
          left: 10,
          bottom: 10,
          containLabel: true,
        },
        tooltip: {
          trigger: "axis",
          backgroundColor: "#fff",
          borderColor: "#DCDCDC",
          borderWidth: 1,
          textStyle: {
            color: "rgba(0, 0, 0, .9)",
          },
          formatter: function (params) {
            let str = ``;
            for (let i = 0; i < params.length; i++) {
              const paramsItem = params[i], dataItem = res.data[paramsItem.dataIndex]
              console.log('params', params)

              str += `<div style="width: calc(141vw / 19.2); padding: calc(16vw / 19.2) 0 calc(20vw / 19.2) calc(16vw / 19.2);"><div style="color: rgba(0, 0, 0, .4); font-size: calc(12vw / 19.2); line-height: calc(20vw / 19.2);">${dataItem.create_time.substring(0, 16)}</div> 
              <div style="margin-top: calc(16vw / 19.2);display: flex; flex-direction: row; align-items: center;"> <div style="width: calc(8vw / 19.2); height: calc(8vw / 19.2); background-color: #3D71F6; border-radius: 50%; margin-right: calc(12vw / 19.2)"></div>  ${paramsItem.seriesName} ${paramsItem.value}</div></div>`;
            }
            return str;
          },
        },
        xAxis: {
          type: "category",
          offset: 0,
          boundaryGap: false,
          data: xAxisData,
          name: "",
          axisTick: {
            show: true,
            alignWithLabel: false,
            interval: 2,
            lineStyle: {
              color: '#D7DAE1'
            }
          },
          splitLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#696969",
            interval: 2
          },
          axisLine: {
            show: true,
            lineStyle: {
              color: 'rgba(57, 70, 106, .2)'
            },
          },
        },
        yAxis: {
          //name: "万元",
          type: "value",
          nameTextStyle: {
            color: "#696969",
            align: "center",
            fontWeight: "400",
          },
          minInterval: 1,
          axisLine: {
            show: true,
            lineStyle: {
              color: "#fff",
            },
          },
          axisLabel: {
            color: "#696969",
          },
          splitLine: {
            lineStyle: {
              color: 'rgba(57, 70, 106, .1)',
            },
          },
        },
        series: [
          {
            name: "存证数",
            type: "line",
            smooth: true,
            showSymbol: false,
            data: seriesData,
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  // 渐变颜色
                  { offset: 0, color: "rgba(246, 173, 58)" },
                  // { offset: 0.5, color: "rgba(221, 190, 76, .2)" },
                  { offset: 1, color: "rgba(246, 173, 58, 0)" },
                ],
                global: false,
              },
            },
            lineStyle: {
              // 阴影部分
              shadowOffsetX: 0, // 折线的X偏移
              shadowOffsetY: 4, // 折线的Y偏移
              shadowBlur: 4, // 折线模糊
              shadowColor: "rgba(246, 173, 58, .1)", //折线颜色
            },
          },
        ],
      });
    }
  };

  // 存证成败数目与比例统计
  const getTxstastics = async (channel) => {
    let res = await request("get", "/v3/fabric/monitoring/tx_stastics", {
      channel,
    });
    if (res.code == 0) {
      let total = res.data.failed + res.data.success;
      setGaugeInfo({
        data: [{ name: "成功率", value: (res.data.success / total) * 100 }],
      });
    }
  };

  useEffect(() => {
    getAllBrief();
    getBaseInfoQuery();
    getStaticBlock();
  }, []);

  useEffect(() => {
    if (store.channel) {
      getBlocksBrief(3, store.channel);
      getTxsBrief(6, store.channel);
      getTxBlock(store.channel);
      getTxstastics(store.channel);
    }
  }, [store.channel]);

  return (
    <div className={styles["home"]}>
      <div className={styles["home-header"]}>
        <div className={styles["home-header_left"]}>
          <div className={styles["left-title"]}>
            {/* <p>区块链浏览器</p> */}
            <p>
              国家基础设施{" "}
              <span className={styles["left-title_color"]}>可信存证</span>
            </p>
          </div>
          <div className={styles["left-subTitle"]}>
            {/* UDAG跨链数据路由协议，提供可信外部数据Oracle服务，支持跨链外部访问，并行BFT实现秒级存证确认；100+节点高效共识，提供全新块数据存储结构 */}
            打造链网协同新基建，推动数字资产价值化。
          </div>
        </div>
        <div className={styles["home-header_right"]}>
          <img src={imageIcon} />
          <img src={ellipse998Icon} />
        </div>
        <div className={styles["home-header_center"]}>
          <img src={detailIcon} />
        </div>
      </div>
      <div className={styles["home-cards"]}>
        <img src={ellipse999Icon} />
        {baseInfoList.map((item, index) => {
          return (
            <div key={index} className={styles["home-cards_item"]}>
              <div className={styles["item-decoration"]}>{item.decoration}</div>
              <div className={styles["item-icon"]}>
                <img src={item.icon} />
              </div>
              <div className={styles["item-desc"]}>
                <div className={styles["item-desc_name"]}>{item.name}</div>
                <div className={styles["item-desc_title"]}>{item.title}</div>
              </div>
            </div>
          );
        })}
      </div>
      <div className={styles["home-statistics"]}>
        <div className={styles["home-statistics_title"]}>
          统计信息
          <img src={decorateIcon} />
        </div>
        <div className={styles["home-statistics_info"]}>
          <div className={styles["info-echart"]}>
            <div className={styles["info-echart_title"]}>24h用户数</div>
            <ConfigEcharts initId="echart1Id" type="bar" chartsInfo={barInfo} />
          </div>
          <div className={styles["info-echart"]}>
            <div className={styles["info-echart_title"]}>24h存证数</div>
            <ConfigEcharts initId="echart2Id" type="pie" chartsInfo={pieInfo} />
          </div>
        </div>
      </div>
      <div className={styles["home-blocks"]}>
        <div className={styles["home-blocks_title"]}>
          实时区块信息
          <img src={decorateIcon} />
          <div className={styles["title-search"]}>
            <Select
              placeholder="通道"
              value={store.channel}
              style={{ width: 180 }}
              options={channels}
              onChange={(e) => store.actionChannel(e)}
            />
          </div>
        </div>
        <div className={styles["home-blocks_info"]}>
          {blocksBrief.map((item, index) => {
            return (
              <div key={index} className={styles["info-item"]}>
                <div className={styles["info-item_card"]}>
                  <div className={styles["card-height"]}>
                    <label>区块高度&nbsp;:&nbsp;</label>
                    <span>{item.height}</span>
                  </div>
                  <div className={styles["card-hash"]}>
                    <label>Hash&nbsp;&nbsp;&nbsp;值&nbsp;:&nbsp;</label>
                    <span>{item.block_hash}</span>
                  </div>
                  <div className={styles["card-time"]}>
                    <label>出块时间&nbsp;:&nbsp;</label>
                    <span>{getTimeFormat("y-m-d h:i:s", item.time)}</span>
                  </div>
                </div>
                <Link
                  to={{
                    pathname: "/blockInfo"
                  }}
                  onClick={() => store.actionBlockHeight(item.height)} >
                  <div className={styles["info-item_link"]}>
                    <div className={styles["link-left"]}>数据明细</div>
                    <div className={styles["link-right"]}>
                      <img src={arrowleftIcon} />
                    </div>
                  </div>
                </Link>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles["home-traffic"]}>
        <div className={styles["home-traffic_title"]}>
          实时存证信息
          <img src={decorateIcon} />
        </div>
        <div className={styles["home-traffic_info"]}>
          <Table
            columns={columns}
            dataSource={txsBrief}
            pagination={false}
            size={"middle"}
          />
        </div>
      </div>
    </div>
  );
};

export default observer(Home);
