/* eslint-disable react/prop-types */
import * as echarts from "echarts";
import React, { Component } from "react";
import {
  barOptions,
  blockOptions,
  pieOptions,
  gaugeOptions,
} from "./configOptions";

import styles from "./index.module.less";
export default class ConfigEcharts extends Component {
  constructor(props) {
    super(props);
    this.state = {
      barInfo: null,
    };
  }
  // 初始化渲染不同类型echarts
  dyamicRenderTypes(id, type, chartsInfo) {
    let { xAxis, data, name } = chartsInfo;
    let initChart = echarts.init(document.getElementById(id));
    switch (type) {
      case "bar":
        initChart.setOption(barOptions(xAxis, data, name));
        break;
      case "block":
        initChart.setOption(blockOptions(xAxis, data, name));
        break;
      case "pie":
        initChart.setOption(pieOptions(data, name));
        break;
      case "gauge":
        initChart.setOption(gaugeOptions(data));
        break;
      default:
        break;
    }
    return Component;
  }

  static getDerivedStateFromProps(props, state) {
    let { chartsInfo } = props;
    if (chartsInfo) {
      return {
        barInfo: chartsInfo,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { initId, type } = this.props;
    let { barInfo } = this.state;
    if (prevState.barInfo !== barInfo) {
      this.dyamicRenderTypes(initId, type, barInfo);
    }
  }
  render() {
    const { initId } = this.props;
    return <div className={styles["config-echarts"]} id={initId} />;
  }
}
