import React, { Suspense } from "react";
import { renderRoutes } from "react-router-config";
import { HashRouter as Router, Switch } from "react-router-dom";
import { ConfigProvider } from "antd";
import zhCN from 'antd/locale/zh_CN';
import ReactDOM from "react-dom/client";
import routes from "@/router/config";
import reportWebVitals from "@/reportWebVitals";
import { transformFontSize } from "@/utils/function"

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
    <ConfigProvider
       locale={zhCN}
       theme={{
         token: {
          colorPrimary: '#3D71F6'
         },
         components: {
           Button: {
             colorPrimary: '#292930',
             algorithm: true
           },
           Table: {
             cellFontSize: transformFontSize(16, 1920),
             headerBg: '#263238',
             headerColor: '#fff',
             headerSplitColor: '#263238',
             borderColor: '#CFD5DE',
             cellPaddingInline: 20
           },
           Pagination: {
            itemBg: '#fff',
            itemLinkBg: '#fff',
            itemActiveBg: '#3D71F6'
           }
         }
       }}
    >
      <Suspense>
        <Router>
          <Switch>{renderRoutes(routes)}</Switch>
        </Router>
      </Suspense>
    </ConfigProvider>
  // </React.StrictMode>
);

reportWebVitals();
