/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Table } from "antd";
import ReactJson from "react-json-view";
import { jsJson, analysisJson } from "@/utils/function";
import copy from 'copy-to-clipboard';
import { columns, configColumns, configColumns1 } from './configColumns'

import styles from "./index.module.less";

export default class ConfigDesc extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { dataIndex, type, label, value, gutter, width, children, tableType, key_0, key_1, key_2 } =
      this.props;
    const returnColumns = (tableType) => {
       let tableColumns = null;
       switch(tableType) {
          case 0: tableColumns = columns; break;
          case 1: tableColumns = configColumns(key_0, key_1, key_2); break;
          case 2: tableColumns = configColumns1(key_1, key_2); break;
          default: break;
      }
      return tableColumns;
    }

    let component = null;
    switch (type) {
      case "text":
        component = (
          <span className={styles["desc-value"]}>&nbsp;&nbsp;{value}</span>
        );
        break;
      case "list":
        let parseValue = jsJson(JSON.parse(value)[0]) ? JSON.parse(value)[0] : value;
        component = value ? (
          <div className={styles["desc-list"]}>
            <div className={styles["desc-list_header"]}>
              <p>Value</p>
              <p onClick={() => copy(JSON.stringify(JSON.parse(parseValue)))}>复制</p>
            </div>
            <div className={styles["desc-list_content"]}>
              <ReactJson
                {...{ name: null, displayDataTypes: false, collapsed: true, indentWidth: 4 }}
                src={analysisJson(JSON.parse(parseValue))}
              />
            </div>
          </div>
        ) : null;
        break;
      case "table":
        component = (
          <div className={styles["desc-table"]}>
            <Table
              columns={returnColumns(tableType)}
              dataSource={value}
              bordered
              pagination={false}
            />
          </div>
        );
        break;
      case "slot":
        component = children;
        break;
    }

    let dyamicRenderLabel = label ? (
      <label
        className={`${styles["desc-label"]} ${styles[`gutter-${gutter}`]}`}
      >
        {label}&nbsp;
      </label>
    ) : null;

    return (
      <div key={dataIndex} className={styles["config-desc"]} style={{ width }}>
        {dyamicRenderLabel}
        {component}
      </div>
    );
  }
}
