/* eslint-disable react/prop-types */
import React, { Component } from "react";
import G6 from "@antv/g6";

import raftPnodeIcon from "@/assets/static/raftPnode_icon.png";
import raftYnodeIcon from "@/assets/static/raftYnode_icon.png";
import raftNnodeIcon from "@/assets/static/raftNnode_icon.png";

import styles from "./index.module.less";

let graph = null;

// 自定义边
G6.registerEdge("hvh", {
  draw(cfg, group) {
    let {
      targetNode: {
        _cfg: {
          model: { status },
        },
      },
    } = cfg;
    const startPoint = cfg.startPoint;
    const endPoint = cfg.endPoint;
    const shape = group.addShape("path", {
      attrs: {
        stroke: status ? "#1FC6FF" : "#FF1F1F",
        path: [
          ["M", startPoint.x, startPoint.y],
          ["L", endPoint.x / 3 + (2 / 3) * startPoint.x, startPoint.y], // 三分之一处
          ["L", endPoint.x / 3 + (2 / 3) * startPoint.x, endPoint.y], // 三分之二处
          ["L", endPoint.x, endPoint.y],
        ],
      },
      name: "path-shape",
    });
    return shape;
  },
});

// 定义状态显示不同的图标节点
const getStatusIcons = (status) => {
  let img = "";
  switch (status) {
    case 2:
      img = raftPnodeIcon;
      break;
    case 1:
      img = raftYnodeIcon;
      break;
    case 0:
      img = raftNnodeIcon;
      break;
    default:
      break;
  }
  return img;
};

export default class ConfigTopology extends Component {
  constructor(props) {
    super(props);
    this.state = {
      treeNodes: null,
    };
  }

  // 初始化渲染基于G6的拓扑图插件
  renderTopologyCharts(treeNodes) {
    graph && graph.destroy();
    graph = new G6.TreeGraph({
      container: "topologyId",
      fitView: true,
      fitViewPadding: 20,
      modes: {
        default: [
          {
            type: "collapse-expand",
            onChange: function onChange(item, collapsed) {
              const data = item.get("model");
              data.collapsed = collapsed;
              return true;
            },
          },
          "drag-canvas",
          "zoom-canvas",
        ], // 允许拖拽画布、放缩画布、拖拽节点、设置高亮
      },
      layout: {
        type: "dendrogram",
        controlPoints: true,
        direction: "LR",
        nodeSep: 100,
        rankSep: 300,
      },
    });
    graph.node((node) => {
      return {
        id: node.id,
        type: "image",
        size: [82, 58],
        img: getStatusIcons(node.status),
        anchorPoints: [
          [0, 0.5],
          [1, 0.5],
        ],
        labelCfg: {
          position: "bottom",
          offset: 10,
        },
      };
    });
    graph.edge((edge) => {
      return {
        id: edge.id,
        type: "hvh",
        style: {
          lineWidth: 1,
        },
      };
    });
    graph.data(treeNodes);
    graph.render();
  }

  static getDerivedStateFromProps(props, state) {
    let { treeNodes } = props;
    if (treeNodes) {
      return {
        treeNodes,
      };
    } else {
      return null;
    }
  }

  componentDidUpdate(prevProps, prevState) {
    let { treeNodes } = this.state;
    if (prevState.treeNodes !== treeNodes) {
      this.renderTopologyCharts(treeNodes);
    }
  }
  render() {
    return (
      <div className={styles["config-topology"]}>
        <div className={styles["config-topology_title"]}>节点拓扑图</div>
        <div className={styles["config-topology_tip"]}>
          {[
            {
              icon: raftYnodeIcon,
              label: "普通节点",
            },
            {
              icon: raftNnodeIcon,
              label: "锚节点",
            },
          ].map((item, index) => {
            return (
              <div className={styles["tip-item"]} key={index}>
                <img src={item.icon} />
                <label>{item.label}</label>
              </div>
            );
          })}
        </div>
        <div className={styles["config-topology_id"]} id="topologyId"></div>
      </div>
    );
  }
}
