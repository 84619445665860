import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Select } from "antd";
import { request } from "@/utils/request";
import { datemonth, getTimeFormat } from "@/utils/function";

import ConfigEcharts from "@/components/echarts/configEcharts";
import ConfigModal from "@/components/modal/configModal";

import decorateIcon from "@/assets/static/decorate_icon.png";
import monitorIcon from "@/assets/static/monitorImage_icon.png";
import monitor1Icon from "@/assets/static/monitorImage1_icon.png";
import monitor2Icon from "@/assets/static/monitorImage2_icon.png";
import monitor3Icon from "@/assets/static/monitorImage3_icon.png";

import ellipse999Icon from "@/assets/static/ellipse999_icon.png";

import styles from "./index.module.less";

const Monitor = () => {
  const [open, setModalOpen] = useState(false);

  // 查询所有节点
  const [nodes, setNodes] = useState(null);

  const [cardList, setCardList] = useState([
    { count: 0, name: "组织总数", type: "org" },
    { count: 0, name: "智能合约数", type: "haincodes" },
    { count: 0, name: "节点总数", type: "nodes" },
    { count: 0, name: "Orderer", type: "orderer" },
    { count: 0, name: "Peer", type: "peer" },
  ]);
  const [channelList, setChannelList] = useState([
    {
      icon: monitorIcon,
      count: 0,
      name: "上一区块",
      unit: "个月前",
      type: "time",
    },
    { icon: monitor1Icon, count: 0, name: "存证总数", type: "channelTotal" },
    { icon: monitor2Icon, count: 0, name: "区块高度", type: "height" },
    { icon: monitor3Icon, count: 0, name: "存证失败总数", type: "errorTotal" },
  ]);

  // 存证数与时间
  const [chartsTimeInfo, setChartsTimeInfo] = useState(null);

  // 存证量与区块号
  const [chartBlockHeightInfo, setChartBlockHeightInfo] = useState(null);

  // 存证量与区块数
  const [chartsBlockInfo, setChartsBlockInfo] = useState(null);

  // 通道
  const [channels, setChannels] = useState([]);

  // 查询所有通道
  const getAllBrief = async () => {
    let res = await request("get", "/v3/fabric/channel/allBrief");
    if(res.code == 0) {
      console.log('查询到所有通道', res);
      setChannels(res.data.map(v => ({ value: v, label: v })))
    }
  }

  // 查询区块简要信息
  const getBlocksBrief = async (num, channel) => {
    let res = await request("get", "/v3/fabric/monitoring/blocksBrief", {
      channel,
      num,
    });
    if (res.code == 0) {
      let arr = channelList.map((item) => {
        if (item.type == "time") {
          let month = datemonth(
            getTimeFormat("y-m-d", new Date()),
            getTimeFormat("y-m-d", res.data[0].time)
          );
          item.count = month;
        }
        if (item.type == "height") item.count = res.data[0].height;
        return item;
      });
      setChannelList(arr);
      setChartBlockHeightInfo({
        xAxis: res.data.map((item) => item.height),
        data: res.data.map((item) => item.count),
        name: "存证量",
      });
    }
  };

  // 统计某个通道里存证成功数与失败数
  const getTxStastics = async (channel) => {
    let res = await request("get", "/v3/fabric/monitoring/tx_stastics", {
      channel,
    });
    if (res.code == 0) {
      let arr = channelList.map((item) => {
        if (item.type == "channelTotal")
          item.count = res.data["success"] + res.data["failed"];
        if (item.type == "errorTotal") item.count = res.data["failed"];
        return item;
      });
      setChannelList(arr);
    }
  };

  // 查询通道中的组织数
  const getChannelOrgs = async (channel) => {
    let res = await request("get", "/v3/fabric/monitoring/getChannelOrgs", {
      channel,
    });
    if (res.code == 0) {
      let arr = cardList.map((item) => {
        if (item.type == "org") {
          item.count = res.data.AllOrgs.length;
        }
        return item;
      });
      setCardList(arr);
    }
  };

  // 查询通道内智能合约详情
  const getHaincodes = async (channel) => {
    let res = await request("get", "/v3/fabric/chaincodes", { channel });
    if (res.code == 0) {
      let arr = cardList.map((item) => {
        if (item.type == "haincodes") {
          item.count = res.data.length;
        }
        return item;
      });
      setCardList(arr);
    }
  };

  // 循环调用节点状态
  const promiseAllList = (arr) => {
    return new Promise((resolve) => {
      return Promise.all(
        arr.map((item, index) => {
          return new Promise(async (resolve) => {
            let res = await request("get", "/v3/fabric/monitoring/aliveQuery", {
              host: `${item.host}:${item.health_port}`,
            });
            item.key = index;
            item.status = res.data.status == "OK" ? "正常" : "不正常";
            resolve(item);
          });
        })
      ).then((list) => {
        resolve(list);
      });
    });
  };

  // 查询所有节点
  const getAllNodes = async () => {
    let res = await request("get", "/v3/config/node/query");
    if (res.code == 0) {
      let arr = cardList.map((item) => {
        if (item.type == "nodes") item.count = res.data.length;
        if (item.type == "peer")
          item.count = res.data.filter((v) => v["node_type"] == "peer").length;
        if (item.type == "orderer")
          item.count = res.data.filter(
            (v) => v["node_type"] == "orderer"
          ).length;
        return item;
      });
      setCardList(arr);
      promiseAllList(res.data).then((list) => {
        setNodes(list);
      });
    }
  };

  // 存证与时间统计
  const getTxTime = async (channel) => {
    let res = await request("get", "/v3/fabric/static/tx_time", { channel });
    if (res.code == 0) {
      setChartsTimeInfo({
        name: "时间存证数",
        data: res.data.map((item) => ({
          name: item.desc,
          value: item.txCount,
        })),
      });
    }
  };

  // 区块与存证数统计
  const getTxBlock = async (channel) => {
    let res = await request("get", "/v3/fabric/static/tx_block", { channel });
    if (res.code == 0) {
      setChartsBlockInfo({
        name: "区块存证数",
        data: res.data.map((item) => ({ name: item.type, value: item.count })),
      });
    }
  };

  useEffect(() => {
    getAllBrief();
    getAllNodes();
  }, [])

  useEffect(() => {
    if(store.channel) {
      getChannelOrgs(store.channel);
      getHaincodes(store.channel);
      getBlocksBrief(10, store.channel);
      getTxStastics(store.channel);
      getTxTime(store.channel);
      getTxBlock(store.channel);
    }
  }, [store.channel]);

  return (
    <div className={styles["monitor"]}>
      <ConfigModal
        {...{
          type: "tables",
          title: "节点详情",
          dataSource1: nodes?.filter((v) => v["node_type"] == "peer"),
          dataSource2: nodes?.filter((v) => v["node_type"] == "orderer"),
          open,
          configCancel: () => setModalOpen(false),
        }}
      />
      <div className={styles["monitor-header"]}>
        <div className={styles["monitor-header_card"]}>
          <div className={styles["card-info"]}>
            {cardList.map((item, index) => {
              return (
                <div key={index} className={styles["card-info_item"]}>
                  <p>{item.count}</p>
                  <p>{item.name}</p>
                </div>
              );
            })}
            <div
              className={styles["card-info_btn"]}
              onClick={() => setModalOpen(true)}
            >
              <span>节点查看</span>
            </div>
          </div>
        </div>
      </div>
      <div className={styles["monitor-channel"]}>
        <img src={ellipse999Icon} />
        <div className={styles["monitor-channel_title"]}>
          通道数据
          <img src={decorateIcon} />
          <div className={styles["title-search"]}>
            <Select placeholder="通道" style={{ width: 180 }}
              options={channels} value={store.channel} onChange={(e) => store.actionChannel(e)}
            />
          </div>
        </div>
        <div className={styles["monitor-channel_card"]}>
          {channelList.map((item, index) => {
            return (
              <div key={index} className={styles["card-item"]}>
                <div className={styles["card-item_img"]}>
                  <img src={item.icon} />
                </div>
                <div className={styles["card-item_desc"]}>
                  <div className={styles["desc-label"]}>
                    {item.count}
                    <span className={styles["desc-label_unit"]}>
                      {item.unit ? item.unit : null}
                    </span>
                  </div>
                  <div className={styles["desc-name"]}>{item.name}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className={styles["monitor-statistics"]}>
        <div className={styles["monitor-statistics_title"]}>
          统计信息
          <img src={decorateIcon} />
        </div>
        <div className={styles["monitor-statistics_info"]}>
          <div className={styles["info-echart"]}>
            <div className={styles["info-echart_title"]}>存证数/时间</div>
            <ConfigEcharts
              initId="echart1Id"
              type="pie"
              chartsInfo={chartsTimeInfo}
            />
          </div>
          <div className={styles["info-echart"]}>
            <div className={styles["info-echart_title"]}>存证量/区块号</div>
            <ConfigEcharts
              initId="echart2Id"
              type="block"
              chartsInfo={chartBlockHeightInfo}
            />
          </div>
          <div className={styles["info-echart"]}>
            <div className={styles["info-echart_title"]}>存证量/区块数</div>
            <ConfigEcharts
              initId="echart3Id"
              type="pie"
              chartsInfo={chartsBlockInfo}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Monitor);
