import axios from "axios";
const http = axios.create();

http.defaults.withCredentials = false;

http.interceptors.request.use(
  (config) => {
    const notToken = config.notToken;
    const headers = config.headers;

    const token = localStorage.getItem("Token");
    if (!notToken && token) {
      headers.Authorization = `${token}`;
    }
    if (!notToken) {
      headers.userId = localStorage.getItem("userId");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

http.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    return Promise.reject(error);
  }
);

/**
 * @param {String} method                 // 请求的类型
 * @param {String} url                    // 请求地址
 * @param {Object} data | @default {}     // 接受的参数
 */

// http://122.97.130.73:8003
export const request = (method, url, data, baseURL = process.env.NODE_ENV === 'development' ? '/api' : "/bcBrowserApi") => {
  let type = method == "get" ? "params" : "data";
  return new Promise((resolve) => {
    let option = {
      method,
      url,
      [type]: data,
      baseURL,
      responseType: "json",
    };
    http(option)
      .then((res) => {
        resolve(res.data);
      })
      .catch((err) => {
        console.log("error", err);
      });
  });
};
