/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { Modal, Table } from "antd";
import { transformFontSize } from "@/utils/function";

import styles from "./index.module.less";

const column1 = [
  {
    title: "网络节点类型",
    dataIndex: "node_type",
    key: "node_type",
  },
  {
    title: "域名",
    dataIndex: "host",
    key: "host",
  },
  {
    title: "端口",
    dataIndex: "port",
    key: "port",
    render: (text) => <span style={{ color: "#7782FF" }}>{text}</span>,
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <span style={{ color: text == "正常" ? "#3EBA59" : "#FF3636" }}>
        {text}
      </span>
    ),
  },
];

const column2 = [
  {
    title: "组织节点类型",
    dataIndex: "node_type",
    key: "node_type",
  },
  {
    title: "域名",
    dataIndex: "host",
    key: "host",
  },
  {
    title: "端口",
    dataIndex: "port",
    key: "port",
    render: (text) => <span style={{ color: "#7782FF" }}>{text}</span>,
  },
  {
    title: "归属组织",
    dataIndex: "org",
    key: "org",
  },
  {
    title: "状态",
    dataIndex: "status",
    key: "status",
    render: (text) => (
      <span style={{ color: text == "正常" ? "#3EBA59" : "#FF3636" }}>
        {text}
      </span>
    ),
  },
];

export default class ConfigModal extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  render() {
    const {
      type,
      title,
      open,
      configCancel,
      children,
      dataSource1,
      dataSource2,
    } = this.props;
    let component = null;
    switch (type) {
      case "detail":
        component = (
          <div className={styles["modal-detail"]}>
            {[
              { label: "主题信息", child: children["props"].children[0] },
              { label: "签发者信息", child: children["props"].children[1] },
              { label: "证书信息", child: children["props"].children[2] },
            ].map((item, index) => {
              return (
                <div className={styles["modal-detail_item"]} key={index}>
                  <div className={styles["item-label"]}>{item.label}</div>
                  <div className={styles["item-slot"]}>{item.child}</div>
                </div>
              );
            })}
          </div>
        );
        break;
      case "preview":
        component = (
          <div className={styles["modal-detail"]}>
            {[{ label: "策略预览", child: children["props"].children }].map(
              (item, index) => {
                return (
                  <div className={styles["modal-detail_item"]} key={index}>
                    <div className={styles["item-label"]}>{item.label}</div>
                    <div className={styles["item-slot"]}>{item.child}</div>
                  </div>
                );
              }
            )}
          </div>
        );
        break;
      case "tables":
        component = (
          <div className={styles["modal-tables"]}>
            <div className={styles["modal-tables_left"]}>
              <Table
                columns={column1}
                dataSource={dataSource1}
                pagination={false}
              />
            </div>
            <div className={styles["modal-tables_right"]}>
              <Table
                columns={column2}
                dataSource={dataSource2}
                pagination={false}
              />
            </div>
          </div>
        );
        break;
    }
    return (
      <Modal
        width={transformFontSize(1200, 1920)}
        title={title}
        open={open}
        onCancel={configCancel}
        footer={null}
      >
        <div className={styles["config-modal"]}>{component}</div>
      </Modal>
    );
  }
}
