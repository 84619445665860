import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import {
  Table,
  DatePicker,
  Space,
  Input,
  Button,
  ConfigProvider,
  Tooltip
} from "antd";

import ConfigPagination from "@/components/pagination/configPagination.jsx";

import { Link } from "react-router-dom";
import { request } from "@/utils/request";
import { getTimeFormat } from "@/utils/function";
import styles from "./index.module.less";

import ellipse999Icon from "@/assets/static/ellipse999_icon.png";
import ripple1Icon from "@/assets/static/ripple1_icon.png";

import previousIcon from "@/assets/static/previous_icon.png";

const { RangePicker } = DatePicker;

const columns = [
  {
    title: "区块号",
    dataIndex: "height",
    key: "height",
    render: (text, record) => {
      return (
        <div className={styles["block-text_color"]}>
          <Link
            to={{ pathname: "/blockInfo", state: { height: record.height } }}
          >
            {text}
          </Link>
        </div>
      );
    },
    width: 100
  },
  {
    title: "区块时间",
    dataIndex: "time",
    key: "time",
    ellipsis: true,
    width: 200,
    render: (text) => <Tooltip title={getTimeFormat("y-m-d h:i:s", text)}><span>{getTimeFormat("y-m-d h:i:s", text)}</span></Tooltip>,
  },
  {
    title: "存证量",
    dataIndex: "count",
    key: "count",
    width: 100
  },
  {
    title: "块hash",
    dataIndex: "block_hash",
    key: "block_hash",
    ellipsis: true,
    render: (text, record) => (
      <div className={styles["block-text_color"]}>
        <Link to={{ pathname: "/blockInfo" }} onClick={() => store.actionBlockHeight(record.height)}>
          <Tooltip title={text}>{text}</Tooltip>
        </Link>
      </div>
    )
  },
  {
    title: "区块大小(Bytes) )",
    dataIndex: "size",
    key: "size",
    width: 150
  },
];

const Block = () => {
  const [query, setQuery] = useState({
    start_time: "",
    stop_time: "",
    hash: "",
  });
  const [pagination, setPagination] = useState({
    page: 1,
    count: 10,
  });
  const [total, setTotal] = useState(0);
  const [dataSource, setDataSource] = useState([]);

  // 查询时间转时间戳
  const timestampQuery = (e) => {
    let timestamp1 = "",
      timestamp2 = "";
    if (e) {
      timestamp1 = new Date(e[0]).getTime();
      timestamp2 = new Date(e[1]).getTime();
    }
    setQuery({
      start_time: `${timestamp1}`,
      stop_time: `${timestamp2}`,
    });
  };

  // 查询hash值
  const hashQuery = (e) => {
    setQuery({
      hash: e,
    });
  };

  // 查询列表数据
  const listQuery = () => {
    getBlocksSummaryByPage(
      Object.assign({}, query, { channel: store.channel, ...pagination })
    );
  };

  // 查询区块概要信息列表
  const getBlocksSummaryByPage = async (data) => {
    let res = await request(
      "get",
      "/v3/fabric/monitoring/blocksSummaryByPage",
      data
    );
    if (res.code == 0) {
      if (res.data.data) {
        let arr = res.data.data.map((item, index) =>
          Object.assign({}, item, { key: index })
        );
        setTotal(res.data.counts);
        setDataSource(arr);
      } else {
        setTotal(0);
        setDataSource([]);
      }
    }
  };

  useEffect(() => {
    listQuery();
  }, [pagination]);

  return (
    <ConfigProvider
      theme={{
        components: {
          Button: {
            colorPrimary: "#3d71f6",
            algorithm: true,
          },
        },
      }}
    >
      <div className={styles["block"]}>
        <img src={ripple1Icon} />
        <div className={styles["block-header"]}>
          <div className={styles["block-header_query"]}>
            <Space size={12}>
              <RangePicker
                showTime
                onChange={(e) => {
                  timestampQuery(e);
                }}
              />
              <Input
                placeholder="请输入hash值查询"
                suffix={<img src={previousIcon} style={{ width: 20 }} />}
                onChange={(e) => {
                  hashQuery(e.target.value);
                }}
              />
              <Button type="primary" onClick={() => listQuery()}>
                刷新
              </Button>
            </Space>
          </div>
        </div>
        <div className={styles["block-table"]}>
          <img src={ellipse999Icon} />
          <div className={styles["block-table_info"]}>
            <Table
              columns={columns}
              dataSource={dataSource}
              pagination={false}
              size={"middle"}
            />
            <div className={styles["info-pagination"]}>
              <ConfigPagination
                total={total}
                paginationFunc={(page, pageSize) => {
                  setPagination({ page, count: pageSize });
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </ConfigProvider>
  );
};

export default observer(Block);
