import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Table, Tooltip } from "antd";
import { request } from "@/utils/request";
import { getTimeFormat } from "@/utils/function";

import ConfigTopology from "@/components/topology/configTopology.jsx";

import ellipse999Icon from "@/assets/static/ellipse999_icon.png";

import styles from "./index.module.less";

const columns = [
  {
    title: "节点名称",
    dataIndex: "host",
    key: "host",
    ellipsis: true,
    render: (text) => {
      return <Tooltip title={text}>
          <span>{ text }</span>
      </Tooltip>
    }
  },
  {
    title: "端口",
    dataIndex: "port",
    key: "port",
    width: 100
  },
  {
    title: "节点时间",
    dataIndex: "time",
    key: "time",
    ellipsis: true,
    render: (text) => {
      return <Tooltip title={text}>
          <span>{ text }</span>
      </Tooltip>
    }
  },
  {
    title: "节点活性",
    dataIndex: "active",
    key: "active",
    width: 100
  },
];

const Network = () => {
  const [nodes, setNodes] = useState(null);
  const [treeNodes, setTreeNodes] = useState(null);

  // 网络拓扑图查询网络中的节点
  const getNetworks = async (channel) => {
    let res = await request("get", "/v3/fabric/monitoring/networks", {
      channel,
    });
    if (res.code == 0) {
      let {
        orders: { orderers },
        peers,
      } = res.data;
      let childOrders = Object.keys(orderers).map((key) => {
        return {
          id: key,
          label: key,
          status: 1,
          children: orderers[key]["endpoint"].map((v) => ({
            id: `${v.host}:${v.port}`,
            label: `${v.host}:${v.port}`,
            status: v.IsAnchorPeer ? 0 : 1,
          })),
        };
      });
      let childPeers = Object.keys(peers).map((key) => {
        return {
          id: key,
          label: key,
          status: 1,
          children: peers[key].map((v) => ({
            id: v.Endpoint,
            label: v.Endpoint,
            status: v.IsAnchorPeer ? 0 : 1,
          })),
        };
      });
      const treeNodes = {
        id: "root",
        label: "fabricTest",
        status: 2,
        children: [
          {
            id: "orders",
            label: "orders",
            status: 1,
            children: childOrders,
          },
          {
            id: "peers",
            label: "peers",
            status: 1,
            children: childPeers,
          },
        ],
      };
      setTreeNodes(treeNodes);
    }
  };

  // 循环调用节点状态
  const promiseAllList = (arr) => {
    return new Promise((resolve) => {
      return Promise.all(
        arr.map((item, index) => {
          return new Promise(async (resolve) => {
            let res = await request("get", "/v3/fabric/monitoring/aliveQuery", {
              host: `${item.host}:${item.health_port}`,
            });
            item.key = index;
            item.active = res.data.status == "OK" ? "可用" : "不可用";
            item.time = getTimeFormat("y-m-d h:i:s", res.data.time);
            resolve(item);
          });
        })
      ).then((list) => {
        resolve(list);
      });
    });
  };

  // 查询所有节点
  const getAllNodes = async () => {
    let res = await request("get", "/v3/config/node/query");
    if (res.code == 0) {
      promiseAllList(res.data).then((list) => {
        setNodes(list);
      });
    }
  };

  useEffect(() => {
    getAllNodes();
    getNetworks(store.channel);
  }, []);

  return (
    <div className={styles["network"]}>
      {/* <img src={rippleIcon} /> */}
      <div className={styles["network-detail"]}>
        <img src={ellipse999Icon} />
        {/* <div className={styles["network-detail_title"]}>
          通道-通道1
          <img src={decorateIcon} />
        </div> */}
        <div className={styles["network-detail_info"]}>
          <ConfigTopology treeNodes={treeNodes} />
        </div>
      </div>
      <div className={styles["network-tables"]}>
        <div className={styles["network-tables_left"]}>
          <div className={styles["left-title"]}>Orderer</div>
          <div className={styles["left-table"]}>
            <Table
              columns={columns}
              dataSource={nodes?.filter((v) => v["node_type"] == "orderer")}
              pagination={false}
              size={"middle"}
            />
          </div>
        </div>
        <div className={styles["network-tables_right"]}>
          <div className={styles["right-title"]}>Peer</div>
          <div className={styles["right-table"]}>
            <Table
              columns={columns}
              dataSource={nodes?.filter((v) => v["node_type"] == "peer")}
              pagination={false}
              size={"middle"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default observer(Network);
