/* eslint-disable react/prop-types */
import React, { Component } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Button, Input, Checkbox, ConfigProvider, message } from "antd";
import { request } from "@/utils/request"
import md5 from 'js-md5'

import styles from "@/assets/common/layout.module.less";

import logoIcon from "@/assets/static/fc-logo.png";

class Login extends Component {
  constructor() {
    super();
    this.state = {
       userName: '',
       password: ''
    };
  }
  // 登录操作
  async actionLogin(userName, password) {
     let res = await request("post", "/v3/user/login", { userName, password: md5(password) });
     if(res.code == 0) {
        store.actionLoginstatus(true);
        this.props.history.push('/home');
        message.success('登录成功');
     }
  }
  render() {
    return (
      <ConfigProvider
        theme={{
          components: {
            Button: {
              colorPrimary: "#3D71F6",
              algorithm: true,
            },
          },
        }}
      >
        <div className={styles["login"]}>
          <div className={styles["login-container"]}>
            <div className={styles["login-container_left"]}>
              <div className={styles["left-main"]}>
                 <div className={styles["left-main_header"]}>
                    <img src={logoIcon} />
                    <label>区块链浏览器</label>
                 </div>
                 <div className={styles["left-main_desc"]}>
                    <p>复创区块链浏览器</p>
                    <p>跨链交互协议 <span>高效识别</span>机制</p>
                 </div>
              </div>
            </div>
            <div className={styles["login-container_right"]}>
              <div className={styles["right-main"]}>
                <div className={styles["right-main_header"]}>
                  <label>欢迎登录</label>
                  <span>支持跨链外部访问</span>
                </div>
                <div className={styles["right-main_middle"]}>
                  <div className={styles["middle-header"]}>账号登录</div>
                  <div className={styles["middle-form"]}>
                    <div className={styles["middle-form_wrapper"]}>
                      <Input placeholder="请输入用户名/账号名" value={this.state.userName} onChange={(e) => this.setState({ userName: e.target.value })} />
                    </div>
                    <div className={styles["middle-form_wrapper"]}>
                      <Input.Password placeholder="请输入密码" value={this.state.password} onChange={(e) => this.setState({ password: e.target.value })} />
                    </div>
                    <div className={styles["middle-form_wrapper"]}>
                      <Checkbox>记住密码</Checkbox>
                    </div>
                  </div>
                  <div className={styles["middle-btn"]}>
                    <Button type="primary" block onClick={() => this.actionLogin(this.state.userName, this.state.password)}>
                      登录
                    </Button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </ConfigProvider>
    );
  }
}

export default observer(Login);