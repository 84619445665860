/* eslint-disable react/prop-types */
import React, { Component, Fragment } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { NavLink, Redirect } from "react-router-dom";
import { Layout, Button, Space, message, Modal } from "antd";
import { request } from "@/utils/request";
import { renderRoutes } from "react-router-config";

import styles from "@/assets/common/layout.module.less";

// 导入静态资源
import logoIcon from "@/assets/static/fc-logo.png";
import image1644Icon from "@/assets/static/image1644_icon.png";
import ellipse1001Icon from "@/assets/static/ellipse1001_icon.png";

// 模态框图标资源
import warningIcon from "@/assets/static/warning_icon.png";

const { Header, Content, Footer } = Layout;
class Admin extends Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: "/",
    };
  }
  // 退出登录
  async logout() {
    let res = await request("get", "/v3/user/logout");
    if (res.code == 0) {
      store.actionLoginstatus(false);
      this.props.history.push("/login");
      message.success("退出登录成功!");
    }
  }

  // 模态框确认
  confirmModal() {
    Modal.confirm({
      className: styles["tips-confirm"],
      title: "提示",
      content: (
        <div
          style={{
            color: "rgba(0,0,0,0.65)",
            lineHeight: "20px",
            padding: "10px 0",
          }}
        >
          确认要退出当前系统吗?
        </div>
      ),
      icon: (
        <img
          src={warningIcon}
          style={{ width: "25px", margin: "0 10px 0 0" }}
        />
      ),
      closeIcon: true,
      okText: "确认",
      cancelText: "取消",
      okButtonProps: { style: { backgroundColor: "#3D71F6" } },
      onOk: () => this.logout(),
    });
  }

  static getDerivedStateFromProps(props, state) {
    if (props.location.pathname == "/") {
      return { redirect: "/home" };
    } else {
      return { redirect: props.location.pathname };
    }
  }
  render() {
    let {
      route: { children },
    } = this.props;

    return (
      <div className={styles["admin"]}>
        <Fragment>
          <Layout className={styles["admin-layout"]}>
            <Layout className={styles["admin-layout_background"]}>
              <div className={styles["background-left"]}>
                {[
                  { img: image1644Icon, property: "background-left_top" },
                  { img: ellipse1001Icon, property: "background-left_bottom" },
                ].map((item, index) => {
                  return (
                    <img
                      key={index}
                      src={item.img}
                      className={styles[item.property]}
                    />
                  );
                })}
              </div>
            </Layout>
            <Header className={styles["admin-layout_header"]}>
              <div className={styles["header-logo"]}>
                <img src={logoIcon} />
                <span className={styles["header-logo_title"]}>
                  区块链浏览器
                </span>
              </div>
              <div className={styles["header-nav"]}>
                {[
                  { path: "/home", name: "首页" },
                  { path: "/block", name: "区块" },
                  { path: "/transaction", name: "存证" },
                  { path: "/netWork", name: "网络信息" },
                ].map((item, index) => {
                  return (
                    <NavLink key={index} to={item.path}>
                      {item.name}
                    </NavLink>
                  );
                })}
              </div>
              <div className={styles["header-btns"]}>
                {store.loginStatus ? (
                  <Button
                    type="primary"
                    className={styles["header-btns_two"]}
                    onClick={() => this.confirmModal()}
                  >
                    安全退出
                  </Button>
                ) : (
                  <Button
                    type="primary"
                    className={styles["header-btns_two"]}
                    onClick={() => this.props.history.push("/login")}
                  >
                    用户登录
                  </Button>
                )}
              </div>
            </Header>
            <Content className={styles["admin-layout_content"]}>
              {renderRoutes(children)}
              <Redirect from="*/" to={this.state.redirect} />
            </Content>
            <Footer className={styles["admin-layout_footer"]}>
              <div className={styles["footer-tip"]}>
                {/* <div className={styles["footer-tip_top"]}>
                  Copyright © www.chdpp.com,all rights reserved.{" "}
                </div> */}
                <div className={styles["footer-tip_bottom"]}>
                   Copyright © 2023  复创智能制造   版权所有
                </div>
              </div>
            </Footer>
          </Layout>
        </Fragment>
      </div>
    );
  }
}

export default observer(Admin);
