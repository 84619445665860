/* eslint-disable react/prop-types */
/* eslint-disable react/jsx-key */
import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import store from "@/store";
import { Input } from "antd";
import { request } from "@/utils/request";
import { getTimeFormat, jsJson } from "@/utils/function";

import ConfigDesc from "@/components/desc/configDesc.jsx";
import ConfigModal from "@/components/modal/configModal.jsx";

import styles from "./index.module.less";

import decorateImg from "@/assets/static/decorate_img.png";

import rippleIcon from "@/assets/static/ripple_icon.png";
import ellipse1002Icon from "@/assets/static/ellipse1002_icon.png";

const { Search } = Input;

const BlockDealInfo = (props) => {
  // 存证类型
  const [txType, setTxType] = useState(null);

  // 存证信息
  const [jyInfoList, setJyInfoList] = useState([
    {
      dataIndex: "hash",
      type: "text",
      label: "存证hash",
      value: null,
      gutter: 4,
    },
    {
      dataIndex: "tx_id",
      type: "text",
      label: "存证id",
      value: null,
      gutter: 4,
    },
  ]);

  // 摘要信息
  const [abstractList, setAbstractList] = useState([
    {
      dataIndex: "size",
      type: "text",
      label: "存证数据大小",
      value: null,
      gutter: 4,
      width: "50%",
    },
    {
      dataIndex: "tx_time",
      type: "text",
      label: "存证时间",
      value: null,
      gutter: 8,
      width: "50%",
    },
    {
      dataIndex: "block_height",
      type: "text",
      label: "区块高度",
      value: null,
      gutter: 4,
      width: "50%",
    },
    {
      dataIndex: "tx_type",
      type: "text",
      label: "存证类型",
      value: null,
      gutter: 8,
      width: "50%",
    },
    {
      dataIndex: "valid_status",
      type: "slot",
      label: "存证状态",
      value: null,
      gutter: 4,
      width: "50%",
    },
    {
      dataIndex: "config_block_num",
      type: "text",
      label: "最新修改配置的区块号",
      value: null,
      gutter: 8,
      width: "50%",
    },
    {
      txType: 1,
      dataIndex: "action",
      type: "slot",
      label: "发起者",
      value: "",
      gutter: 4,
      width: "50%",
    },
    {
      txType: 1,
      dataIndex: "endorse",
      type: "slot",
      label: "背书者",
      value: "",
      gutter: 8,
      width: "50%",
    },
  ]);

  // 智能合约
  const [znhyList, setZnhyList] = useState([
    {
      dataIndex: "chaincode_id",
      type: "text",
      label: "名称",
      value: null,
      gutter: 4,
    },
    {
      dataIndex: "function",
      type: "text",
      label: "方法名称",
      value: null,
      gutter: 4,
    },
    {
      dataIndex: "chaincode_version",
      type: "text",
      label: "版本",
      value: null,
      gutter: 4,
    },
    {
      dataIndex: "proposal_response",
      type: "text",
      label: "返回参数",
      value: null,
      gutter: 4,
    },
    {
      dataIndex: "args",
      type: "list",
      label: "参数列表",
      value: null,
      gutter: 4,
    },
  ]);

  // 发起人信息
  const [companyName, setCompanyName] = useState(null);
  const [fqrjbxx, setFqrjbxx] = useState(null);

  // 证书详情
  const [certificate, setCertificate] = useState(null);

  // 发起者 - 证书详情
  const [actionCert, setActionCert] = useState(null);
  // 背书者 - 证书详情
  const [endorseCerts, setEndorseCerts] = useState(null);

  // 发起者 - mspId
  const [mspId, setMspId] = useState(null);

  // 背书者 - 列表
  const [rwSetKey, setRwSetKey] = useState(null);

  // 策略
  const [endorMsp, setEndorMsp] = useState(null);

  // 配置存证 - 存证信息
  const [baseConfig, setBaseConfig] = useState(null);
  // 获取列头 - 字段key1，key2
  const [paramKeys, setParamKeys] = useState(null);

  // 配置存证 - 存证信息1
  const [configTransaction, setConfigTransaction] = useState(null);

  // 获取列头 - 字段key1，key2
  const [paramKeys1, setParamKeys1] = useState(null);

  // 查询存证详情
  const getTxDetail = async (channel, id) => {
    let res = await request("get", "/v3/fabric/monitoring/txDetail", {
      channel,
      id,
    });
    if (res.code == 0) {
      // 存证类型
      setTxType(res.data["tx_type"]); // 1: 配置存证  3: 普通存证

      // 存证信息
      setJyInfoList(
        jyInfoList.map((item) =>
          Object.assign({}, item, { value: res.data[item.dataIndex] })
        )
      );
      // 摘要信息
      setAbstractList(
        abstractList
          .filter((v) => res.data["tx_type"] != v["txType"])
          .map((item) => {
            switch (item.dataIndex) {
              case "tx_time":
                item.value = getTimeFormat(
                  "y-m-d h:i:s",
                  res.data[item.dataIndex]
                );
                break;
              case "valid_status":
                item.value = res.data[item.dataIndex] ? "成功" : "失败";
                break;
              case "tx_type":
                item.value =
                  res.data[item.dataIndex] == 1 ? "配置存证" : "普通存证";
                break;
              default:
                item.value = res.data[item.dataIndex];
                break;
            }
            return item;
          })
      );

      /**
       * 通过不同的存证类型区分页面
       */
      switch (res.data["tx_type"]) {
        case 1:
          let {
            config_transaction: { policy, initiator, commiter },
            base_config: {
              orderer,
              orderer: { msp },
              peer,
            },
          } = res.data["config_block"];
          let key_0 = Object.keys(msp)[0],
            key_01 = Object.keys(peer)[0],
            key_02 = Object.keys(peer)[1];
          let key_1 = Object.keys(initiator)[0],
            key_2 = Object.keys(commiter)[0];
          setBaseConfig([
            {
              key: 1,
              max_message_count: orderer["batch_size"]["max_message_count"],
              absolute_max_bytes: orderer["batch_size"]["absolute_max_bytes"],
              preferred_max_bytes: orderer["batch_size"]["preferred_max_bytes"],
              batch_timeOut: orderer["batch_timeOut"],
              max_channels: orderer["max_channels"],
              consensus_type: orderer["consensus"]["consensus_type"],
              endpoints: orderer["consensus"]["endpoints"],
              [`${key_0}_root_certs`]:
                msp[key_0].root_certs && msp[key_0].root_certs,
              [`${key_0}_endpoints`]:
                msp[key_0].endpoints && msp[key_0].endpoints.join("\n"),
              [`${key_01}_root_certs`]:
                peer[key_01].root_certs && peer[key_01].root_certs,
              [`${key_01}_endpoints`]:
                peer[key_01].anchor_peers &&
                peer[key_01].anchor_peers.join("\n"),
              [`${key_02}_root_certs`]:
                peer[key_02].root_certs && peer[key_02].root_certs,
              [`${key_02}_endpoints`]:
                peer[key_02].anchor_peers &&
                peer[key_02].anchor_peers.join("\n"),
            },
          ]);
          setParamKeys({ key_0, key_1: key_01, key_2: key_02 });

          setConfigTransaction([
            {
              key: 1,
              [`${key_1}_signcerts`]: initiator[key_1].signcerts,
              [`${key_1}_signature`]: initiator[key_1].signature,
              [`${key_2}_signcerts`]: commiter[key_2].signcerts,
              [`${key_2}_signature`]: commiter[key_2].signature,
              policy,
            },
          ]); // 配置存证 - 存证信息1
          setParamKeys1({ key_1, key_2 });
          break;
        case 3:
          setZnhyList(
            znhyList.map((item) => {
              return Object.assign({}, item, { value: res.data[item.dataIndex] });
            })
          ); // 智能合约
          setCompanyName(res.data["company_name"]); // 发起人
          setFqrjbxx(res.data["signature"]); // 发起人信息
          setActionCert(JSON.parse(res.data.action_cert)); // 发起者 - 证书详情
          setEndorseCerts(JSON.parse(JSON.parse(res.data.endorse_certs)[0])); // 背书者 - 证书详情
          setRwSetKey(
            JSON.parse(res.data.rw_set_key).map((item, index) => {
              let { NameSpace, RKey, WKey } = item;
              return {
                key: index,
                NameSpace,
                Key: RKey && RKey[0]["Key"],
                BlockNum: RKey && RKey[0]["BlockNum"],
                TxNum: RKey && RKey[0]["TxNum"],
                WKey: WKey && WKey[0],
              };
            })
          ); // 背书者 - 列表
          setMspId(res.data["msp_id"]); // 发起者 - mspId
          setEndorMsp(res.data["endor_msp"]); // 策略
          break;
      }
    }
  };

  const [open, setModalOpen] = useState(false);
  const [type, setModalType] = useState("");
  const [title, setModalTitle] = useState("");
  const openModalType = (type, open, title, dataIndex) => {
    setModalType(type);
    setModalOpen(open);
    setModalTitle(title);
    switch (dataIndex) {
      case "action":
        setCertificate(actionCert);
        break;
      case "endorse":
        setCertificate(endorseCerts);
        break;
      case "endor":
        setCertificate(endorMsp);
        break;
    }
  };

  useEffect(() => {
    getTxDetail(store.channel, store.blockId);
  }, []);

  // 展示component
  const showComponent = (json) => {
    let component = null;
    switch (type) {
      case "detail":
        component = (
          <div className={styles["config-detail"]}>
            <div className={styles["config-detail_slot1"]}>
              {[
                {
                  type: "text",
                  label: "通用名称（CN）",
                  value: json.Subject.CommonName,
                  gutter: 4,
                },
              ].map((item, index) => {
                let { type, label, value, gutter } = item;
                return (
                  <ConfigDesc {...{ type, label, value, gutter }} key={index} />
                );
              })}
            </div>
            <div className={styles["config-detail_slot2"]}>
              {[
                {
                  type: "text",
                  label: "通用名称（CN）",
                  value: json.Issuer.CommonName,
                  gutter: 4,
                },
                {
                  type: "text",
                  label: "组织（O）",
                  value: json.Issuer.Organization[0],
                  gutter: 4,
                },
              ].map((item, index) => {
                let { type, label, value, gutter } = item;
                return (
                  <ConfigDesc {...{ type, label, value, gutter }} key={index} />
                );
              })}
            </div>
            <div className={styles["config-detail_slot3"]}>
              {[
                {
                  type: "text",
                  label: "序列号",
                  value: json.SerialNumber,
                  gutter: 4,
                },
                { type: "text", label: "版本", value: json.Version, gutter: 4 },
                {
                  type: "text",
                  label: "签名算法",
                  value: json.AuthorityKeyId,
                  gutter: 4,
                },
                {
                  type: "text",
                  label: "颁发日期",
                  value: getTimeFormat("y-m-d h:i:s", json.NotBefore),
                  gutter: 4,
                },
                {
                  type: "text",
                  label: "截止日期",
                  value: getTimeFormat("y-m-d h:i:s", json.NotAfter),
                  gutter: 4,
                },
                {
                  type: "text",
                  label: "extKey用法",
                  value: json.ExtKeyUsage,
                  gutter: 4,
                },
              ].map((item, index) => {
                let { type, label, value, gutter } = item;
                return (
                  <ConfigDesc {...{ type, label, value, gutter }} key={index} />
                );
              })}
            </div>
          </div>
        );
        break;
      case "preview":
        component = (
          <div className={styles["config-preview"]}>
            <div className={styles["config-preview_slot"]}>
              {json.join(",")}
            </div>
          </div>
        );
    }
    return component;
  };

  return (
    <div className={styles["blockDealInfo"]}>
      <img src={rippleIcon} />
      <ConfigModal
        {...{ type, title, open, configCancel: () => openModalType("", false) }}
      >
        {showComponent(certificate)}
      </ConfigModal>
      <div className={styles["blockDealInfo-detail"]}>
        <div className={styles["blockDealInfo-detail_title"]}>
          存证详情
          <div className={styles["title-query"]}>
            <Search placeholder="查询存证hash" style={{ width: 200 }} />
          </div>
        </div>
        <div className={styles["blockDealInfo-detail_info"]}>
          <img src={decorateImg} />
          <div className={styles["info-desc"]}>
            <div className={styles["info-desc_label"]}>
              <div className={styles["label-title"]}>存证信息</div>
            </div>
            <div className={styles["info-desc_value"]}>
              {jyInfoList.map((item, index) => {
                const { dataIndex, type, label, value, gutter } = item;
                return (
                  <ConfigDesc
                    {...{ dataIndex, type, label, value, gutter }}
                    key={index}
                  />
                );
              })}
            </div>
          </div>
        </div>
        <div className={styles["blockDealInfo-detail_info"]}>
          <img className={styles["img-ellipse_right"]} src={ellipse1002Icon} />
          <div className={styles["info-desc"]}>
            <div className={styles["info-desc_label"]}>
              <div className={styles["label-title"]}>摘要信息</div>
            </div>
            <div
              className={`${styles["info-desc_value"]} ${styles["flex-wrap"]}`}
            >
              {abstractList.map((item, index) => {
                const { dataIndex, type, label, value, gutter, width } = item;
                let component = null;
                switch (dataIndex) {
                  case "action":
                    component = (
                      <div className={styles["desc-slot"]}>
                        <span>{mspId}</span>
                        <a
                          onClick={() =>
                            openModalType("detail", true, null, dataIndex)
                          }
                        >
                          证书详情
                        </a>
                      </div>
                    );
                    break;
                  case "endorse":
                    component = (
                      <div className={styles["desc-slot"]}>
                        <a
                          onClick={() =>
                            openModalType("detail", true, null, dataIndex)
                          }
                        >
                          证书详情
                        </a>
                      </div>
                    );
                    break;
                  default:
                    component = (
                      <span className={styles["desc-status"]}>{value}</span>
                    );
                    break;
                }
                return (
                  <ConfigDesc
                    {...{ dataIndex, type, label, value, gutter, width }}
                    key={index}
                  >
                    {component}
                  </ConfigDesc>
                );
              })}
            </div>
          </div>
        </div>
        {txType == 3 ? (
          <>
            <div className={styles["blockDealInfo-detail_info"]}>
              <div className={styles["info-desc"]}>
                <div className={styles["info-desc_label"]}>
                  <div className={styles["label-title"]}>智能合约</div>
                </div>
                <div className={styles["info-desc_value"]}>
                  {znhyList.map((item, index) => {
                    const { dataIndex, type, label, value, gutter } = item;
                    return (
                      <ConfigDesc
                        {...{ dataIndex, type, label, value, gutter }}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles["blockDealInfo-detail_info"]}>
              <div className={styles["info-desc"]}>
                <div className={styles["info-desc_label"]}>
                  <div className={styles["label-title"]}>信息</div>
                </div>
                <div className={styles["info-desc_value"]}>
                  {[
                    {
                      dataIndex: "company_name",
                      type: "text",
                      label: "发起人",
                      value: companyName,
                      gutter: 4,
                    },
                    {
                      dataIndex: "fqrjbxx",
                      type: "text",
                      label: "发起人签名",
                      value: fqrjbxx,
                      gutter: 4,
                    },
                  ].map((item, index) => {
                    const { dataIndex, type, label, value, gutter } = item;
                    return (
                      <ConfigDesc
                        {...{ dataIndex, type, label, value, gutter }}
                        key={index}
                      />
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles["blockDealInfo-detail_info"]}>
              <div className={styles["info-desc"]}>
                <div className={styles["info-desc_label"]}>
                  <div className={styles["label-title"]}>策略</div>
                </div>
                <div className={styles["info-desc_value"]}>
                  {[
                    {
                      dataIndex: "endorMsp",
                      type: "text",
                      label: "策略",
                      value: endorMsp,
                      gutter: 4,
                    },
                  ].map((item, index) => {
                    const { dataIndex, type, label, value, gutter } = item;
                    return (
                      <ConfigDesc
                        {...{ dataIndex, type, label, value, gutter }}
                        key={index}
                      >
                        {/* <div className={styles["desc-preview"]}>
                          <a
                            onClick={() =>
                              openModalType("preview", true, null, dataIndex)
                            }
                          >
                            { endorMsp }
                          </a>
                        </div> */}
                      </ConfigDesc>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        ) : (
          <>
            <div className={styles["blockDealInfo-detail_info"]}>
              <div className={styles["info-desc"]}>
                <div className={styles["info-desc_label"]}>
                  <div className={styles["label-title"]}>存证信息</div>
                </div>
                <div className={styles["info-desc_value"]}>
                  {[
                    {
                      dataIndex: "table",
                      type: "table",
                      tableType: 1,
                      label: "",
                      value: baseConfig,
                      gutter: 0,
                    },
                  ].map((item, index) => {
                    const { dataIndex, type, label, value, gutter, tableType } =
                      item;
                    return (
                      <ConfigDesc
                        {...{
                          dataIndex,
                          type,
                          label,
                          value,
                          gutter,
                          tableType,
                          ...paramKeys,
                        }}
                        key={index}
                      ></ConfigDesc>
                    );
                  })}
                </div>
              </div>
            </div>
            <div className={styles["blockDealInfo-detail_info"]}>
              <div className={styles["info-desc"]}>
                <div className={styles["info-desc_label"]}>
                  <div className={styles["label-title"]}>存证信息</div>
                </div>
                <div className={styles["info-desc_value"]}>
                  {[
                    {
                      dataIndex: "table",
                      type: "table",
                      tableType: 2,
                      label: "",
                      value: configTransaction,
                      gutter: 0,
                    },
                  ].map((item, index) => {
                    const { dataIndex, type, label, value, gutter, tableType } =
                      item;
                    return (
                      <ConfigDesc
                        {...{
                          dataIndex,
                          type,
                          label,
                          value,
                          gutter,
                          tableType,
                          ...paramKeys1,
                        }}
                        key={index}
                      ></ConfigDesc>
                    );
                  })}
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  );
};

export default observer(BlockDealInfo);
