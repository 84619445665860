/* eslint-disable react/react-in-jsx-scope */
import { Tooltip } from "antd"
// 普通存证columns
export const columns = [
  {
    title: "命名空间",
    dataIndex: "NameSpace",
    key: "NameSpace",
  },
  {
    title: "读集",
    dataIndex: "RKey",
    key: "RKey",
    children: [
      {
        title: "钥匙",
        dataIndex: "Key",
        key: "Key",
      },
      {
        title: "区块高度",
        dataIndex: "BlockNum",
        key: "BlockNum",
      },
      {
        title: "存证索引",
        dataIndex: "TxNum",
        key: "TxNum",
      },
    ],
  },
  {
    title: "写集",
    dataIndex: "writeTotal",
    key: "writeTotal",
    children: [
      {
        title: "写集key",
        dataIndex: "WKey",
        key: "WKey",
      },
    ],
  },
];

// 配置存证columns
export const configColumns = (key_0, key_1, key_2) => {
  return [
    {
      title: "组织",
      dataIndex: "organization",
      key: "organization",
      children: [
        {
          title: "orderer",
          dataIndex: "orderer",
          key: "orderer",
          children: [
            {
              title: "出块大小",
              dataIndex: "batch_size",
              key: "batch_size",
              children: [
                {
                  title: "最大存证数目",
                  dataIndex: "max_message_count",
                  key: "max_message_count",
                },
                {
                  title: "存证最大字节",
                  dataIndex: "absolute_max_bytes",
                  key: "absolute_max_bytes",
                },
                {
                  title: "首选允许字节",
                  dataIndex: "preferred_max_bytes",
                  key: "preferred_max_bytes",
                },
              ],
            },
            {
              title: "出块时间",
              dataIndex: "batch_timeOut",
              key: "batch_timeOut",
            },
            {
              title: "允许最大通道数",
              dataIndex: "max_channels",
              key: "max_channels",
            },
            {
              title: "共识方式",
              dataIndex: "consensus",
              key: "consensus",
              children: [
                {
                  title: "共识类型",
                  dataIndex: "consensus_type",
                  key: "consensus_type",
                },
                {
                  title: "共识节点",
                  dataIndex: "endpoints",
                  key: "endpoints",
                  ellipsis: true,
                  render: (text) => <Tooltip title={text} placement="left">{text}</Tooltip>
                },
              ],
            },
            {
              title: "版本控制",
              dataIndex: "capabilities_1",
              key: "capabilities_1",
              children: [
                {
                  title: key_0,
                  dataIndex: key_0,
                  key: key_0,
                  children: [
                    {
                      title: "组织的根证书",
                      dataIndex: `${key_0}_root_certs`,
                      key: `${key_0}_root_certs`,
                      ellipsis: true,
                    },
                    {
                      title: "节点",
                      dataIndex: `${key_0}_endpoints`,
                      key: `${key_0}_endpoints`,
                      ellipsis: true,
                      render: (text) => <Tooltip title={text} placement="left">{text}</Tooltip>
                    },
                  ],
                }
              ],
            },
          ],
        },
        {
          title: "application",
          dataIndex: "application",
          key: "application",
          children: [
            {
              title: "版本控制",
              dataIndex: "capabilities_2",
              key: "capabilities_2",
              children: [
                {
                  title: key_1,
                  dataIndex: key_1,
                  key: key_1,
                  children: [
                    {
                      title: "组织的根证书",
                      dataIndex: `${key_1}_root_certs`,
                      key: `${key_1}_root_certs`,
                      ellipsis: true,
                    },
                    {
                      title: "节点",
                      dataIndex: `${key_1}_endpoints`,
                      key: `${key_1}_endpoints`,
                      ellipsis: true,
                      render: (text) => <Tooltip title={text} placement="left">{text}</Tooltip>
                    },
                  ],
                },
                {
                  title: key_2,
                  dataIndex: key_2,
                  key: key_2,
                  children: [
                    {
                      title: "组织的根证书",
                      dataIndex: `${key_2}_root_certs`,
                      key: `${key_2}_root_certs`,
                      ellipsis: true,
                    },
                    {
                      title: "节点",
                      dataIndex: `${key_2}_endpoints`,
                      key: `${key_2}_endpoints`,
                      ellipsis: true,
                      render: (text) => <Tooltip title={text} placement="left">{text}</Tooltip>
                    },
                  ],
                },
              ],
            },
          ],
        },
      ],
    },
  ];
};

export const configColumns1 = (key_1, key_2) => {
  return [
    {
      title: "权限策略",
      dataIndex: "policy",
      key: "policy",
      width: 100,
    },
    {
      title: "发起者",
      dataIndex: "initiator",
      key: "initiator",
      children: [
        {
          title: key_1,
          dataIndex: key_1,
          key: key_1,
          children: [
            {
              title: "creator证书",
              dataIndex: `${key_1}_signcerts`,
              key: `${key_1}_signcerts`,
              ellipsis: true,
            },
            {
              title: "签名",
              dataIndex: `${key_2}_signature`,
              key: `${key_2}_signature`,
              ellipsis: true,
            },
          ],
        },
      ],
    },
    {
      title: "提交者",
      dataIndex: "commiter",
      key: "commiter",
      children: [
        {
          title: key_2,
          dataIndex: key_2,
          key: key_2,
          children: [
            {
              title: "creator证书",
              dataIndex: `${key_2}_signcerts`,
              key: `${key_2}_signcerts`,
              ellipsis: true,
            },
            {
              title: "签名",
              dataIndex: `${key_2}_signature`,
              key: `${key_2}_signature`,
              ellipsis: true,
            },
          ],
        },
      ],
    },
  ];
};
