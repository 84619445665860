// 定义柱状图左侧图形元素
import * as echarts from "echarts";
import { transformFontSize } from "@/utils/function";

// 柱状图
const barOptions = (xAxis, seriesData, name) => {
  const colors = [
    [
      { offset: 0, color: "#68D4F7" },
      { offset: 1, color: "rgba(91,104,226,0.19)" },
    ],
    [
      { offset: 0, color: "rgba(137, 163, 164, 1)" },
      { offset: 1, color: "rgba(137, 163, 164, 0.08)" },
    ],
    [
      { offset: 0, color: "rgba(44, 166, 166, 1)" },
      { offset: 1, color: "rgba(44, 166, 166, 0.08)" },
    ],
    [
      { offset: 0, color: "rgba(34, 66, 186, 1)" },
      { offset: 1, color: "rgba(34, 66, 186, 0.08)" },
    ],
  ];
  const leftRect = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
      width: transformFontSize(10, 1920), //柱状图宽
      zWidth: transformFontSize(8, 1920), //阴影折角宽
      zHeight: transformFontSize(4, 1920), //阴影折角高
    },
    buildPath: function (ctx, shape) {
      const api = shape.api;
      const xAxisPoint = api.coord([shape.xValue, 0]);
      const p0 = [shape.x - shape.width / 2, shape.y - shape.zHeight];
      const p1 = [shape.x - shape.width / 2, shape.y - shape.zHeight];
      const p2 = [xAxisPoint[0] - shape.width / 2, xAxisPoint[1]];
      const p3 = [xAxisPoint[0] + shape.width / 2, xAxisPoint[1]];
      const p4 = [shape.x + shape.width / 2, shape.y];

      ctx.moveTo(p0[0], p0[1]);
      ctx.lineTo(p1[0], p1[1]);
      ctx.lineTo(p2[0], p2[1]);
      ctx.lineTo(p3[0], p3[1]);
      ctx.lineTo(p4[0], p4[1]);
      ctx.lineTo(p0[0], p0[1]);
      ctx.closePath();
    },
  });
  // 定义柱状图右侧以及顶部图形元素
  const rightRect = echarts.graphic.extendShape({
    shape: {
      x: 0,
      y: 0,
      width: transformFontSize(10, 1920),
      zWidth: transformFontSize(10, 1920),
      zHeight: transformFontSize(8, 1920),
    },
    buildPath: function (ctx, shape) {
      const api = shape.api;
      const xAxisPoint = api.coord([shape.xValue, 0]);
      const p1 = [shape.x - shape.width / 2, shape.y - shape.zHeight / 2];
      const p3 = [xAxisPoint[0] + shape.width / 2, xAxisPoint[1]];
      const p4 = [shape.x + shape.width / 2, shape.y];
      const p5 = [
        xAxisPoint[0] + shape.width / 2 + shape.zWidth,
        xAxisPoint[1],
      ];
      const p6 = [
        shape.x + shape.width / 2 + shape.zWidth,
        shape.y - shape.zHeight / 2,
      ];
      const p7 = [
        shape.x - shape.width / 2 + shape.zWidth,
        shape.y - shape.zHeight,
      ];
      ctx.moveTo(p4[0], p4[1]);
      ctx.lineTo(p3[0], p3[1]);
      ctx.lineTo(p5[0], p5[1]);
      ctx.lineTo(p6[0], p6[1]);
      ctx.lineTo(p4[0], p4[1]);

      ctx.moveTo(p4[0], p4[1]);
      ctx.lineTo(p6[0], p6[1]);
      ctx.lineTo(p7[0], p7[1]);
      ctx.lineTo(p1[0], p1[1]);
      ctx.lineTo(p4[0], p4[1]);
      ctx.closePath();
    },
  });

  // 注册图形元素
  echarts.graphic.registerShape("leftRect", leftRect);
  echarts.graphic.registerShape("rightRect", rightRect);

  const getRenderItem = (params, api) => {
    const index = params.seriesIndex;
    let location = api.coord([api.value(0) + index, api.value(1)]);
    return {
      type: "group",
      children: [
        {
          type: "leftRect",
          shape: {
            api,
            xValue: api.value(0) + index,
            yValue: api.value(1),
            x: location[0],
            y: location[1],
          },
          style: api.style(),
        },
        {
          type: "rightRect",
          shape: {
            api,
            xValue: api.value(0) + index,
            yValue: api.value(1),
            x: location[0],
            y: location[1],
          },
          style: api.style(),
        },
      ],
    };
  };

  const getSeriesData = () => {
    let data = [];
    seriesData.forEach((item, index) => {
      data.push({
        type: "custom",
        name: item.label,
        renderItem(params, api) {
          return getRenderItem(params, api);
        },
        data: [item.value],
        itemStyle: {
          color: new echarts.graphic.LinearGradient(0, 0, 0, 1, colors[index]),
        },
      });
    });
    return data;
  };

  return {
    xAxis: {
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        color: "#C9CDD4",
      },
      data: xAxis,
    },
    yAxis: {
      name,
      type: "value",
      nameTextStyle: {
        fontSize: transformFontSize(12, 1920),
        padding: [0, 0, 10, transformFontSize(-20, 1920)],
      },
      axisLabel: {
        color: "#000",
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: ["#E5E6EB"],
        },
      },
      axisLine: {
        show: true,
        color: "#C9CDD4",
      },
    },
    series: getSeriesData(),
  };
};

// 区块柱状图
const blockOptions = (xAxis, data, name) => {
  return {
    color: ["#165DFF"],
    xAxis: {
      axisTick: {
        show: false,
      },
      axisLine: {
        show: true,
        color: "#C9CDD4",
      },
      data: xAxis,
    },
    yAxis: {
      name,
      type: "value",
      nameTextStyle: {
        fontSize: transformFontSize(12, 1920),
        padding: [0, 0, 10, transformFontSize(-20, 1920)],
      },
      axisLabel: {
        color: "#000",
      },
      splitLine: {
        show: true,
        lineStyle: {
          type: "dashed",
          color: ["#E5E6EB"],
        },
      },
      axisLine: {
        show: true,
        color: "#C9CDD4",
      },
    },
    series: [
      {
        data,
        type: "bar",
        barWidth: transformFontSize(10, 1920),
        label: {
          normal: {
            show: true,
            position: "top",
            textStyle: {
              color: "black",
            },
          },
        },
      },
    ],
  };
};

// 饼图
const pieOptions = (data, name) => {
  return {
    textStyle: {
      rich: {
        orgname: {
          fontSize: transformFontSize(12, 1920),
        },
        count: {
          fontSize: transformFontSize(12, 1920),
          verticalAlign: "top",
          align: "center",
          color: "#597FF3",
          padding: [0, 0, 0, transformFontSize(10, 1920)],
        },
      },
    },
    legend: {
      top: "center",
      type: "scroll",
      orient: "vertical",
      itemHeight: transformFontSize(10, 1920), //图例图标的高度
      itemWidth: transformFontSize(10, 1920), //图例图标的宽度
      itemGap: transformFontSize(20, 1920),
      right: transformFontSize(10, 1920),
      formatter: (name) => {
        // 解决图列文字过长的问题
        if (!name) return "";
        let count = null;
        data.map((item) => {
          if (item.name == name) {
            count = item.value;
          }
        });
        const arr = [`{orgname|${name}}`, `{count|${count}}`];
        return arr.join("");
      },
      data: data.map((item) => {
        return {
          icon: "circle",
          name: item.name,
          textStyle: {
            color: "#333",
            fontSize: transformFontSize(15, 1920),
          },
        };
      }),
    },
    color: ["#FDCC1F", "#FF8447", "#5DC6FA", "#31D592"],
    series: [
      {
        name,
        data,
        type: "pie",
        radius: [`35%`, `55%`],
        avoidLabelOverlap: false,
        center: [`30%`, `50%`],
        labelLine: {
          show: false,
        },
        label: {
          show: true,
          position: "center",
          formatter: name,
          fontSize: transformFontSize(15, 1920),
        },
      },
    ],
  };
};

// 仪表盘
const gaugeOptions = (data) => {
  return {
    series: [
      {
        type: "gauge",
        itemStyle: {
          color: "#165DFF",
        },
        progress: {
          show: true,
          width: transformFontSize(12, 1920),
        },
        axisLine: {
          lineStyle: {
            width: transformFontSize(12, 1920),
          },
        },
        axisTick: {
          show: true,
          length: transformFontSize(5, 1920),
        },
        splitLine: {
          length: transformFontSize(15, 1920),
          lineStyle: {
            width: transformFontSize(2, 1920),
            color: "#999",
          },
        },
        axisLabel: {
          distance: transformFontSize(25, 1920),
          color: "#999",
          fontSize: transformFontSize(12, 1920),
        },
        anchor: {
          show: true,
          showAbove: true,
          icon: "circle",
          size: transformFontSize(10, 1920),
          itemStyle: {
            // borderWidth: 10,
          },
          lineStyle: {
            color: "#165DFF",
          },
        },
        pointer: {
          width: transformFontSize(3, 1920),
          length: "50%",
          icon: "roundRect",
          itemStyle: {
            color: "#165DFF",
          },
        },
        detail: {
          valueAnimation: true,
          fontSize: transformFontSize(25, 1920),
          formatter: `{value}%`,
          offsetCenter: [0, "50%"],
        },
        data,
      },
    ],
  };
};

// 面积图
const areaOptions = (data) => {
  return {
    
  }
}

export { barOptions, blockOptions, pieOptions, gaugeOptions };
